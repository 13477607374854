import React, { useState } from "react";
import User1 from "../../assest/rm-login/carbon_ibm-event-processing.png";
import User2 from "../../assest/rm-login/Vector (3).png";
import User3 from "../../assest/rm-login/Vector (4).png";
import Client1 from "../../assest/rm-login/client1.png";
import Client2 from "../../assest/rm-login/client2.png";
import Client3 from "../../assest/rm-login/client3.png";
import Client4 from "../../assest/rm-login/client4.png";

function RmLogin() {
  const [QuesOne, setQuesOne] = useState(false);
  const [QuesTwo, setQuesTwo] = useState(false);
  const [QuesThree, setQuesThree] = useState(false);
  const [QuesFour, setQuesFour] = useState(false);
  const [QuesFive, setQuesFive] = useState(false);

  const toggleOne = () => {
    setQuesOne(!QuesOne);
  };

  const toggleTwo = () => {
    setQuesTwo(!QuesTwo);
  };
  const toggleThree = () => {
    setQuesThree(!QuesThree);
  };
  const toggleFour = () => {
    setQuesFour(!QuesFour);
  };
  const toggleFive = () => {
    setQuesFive(!QuesFive);
  };
  return (
    <div className="sm:p-5 md:p-10 xl:p-20 relative mt-16">
      <div className="py-4">
        <p className="text-start font-bold sm:text-2xl md:text-4xl">
          Next-Level Access: Unleash Finovo RM-Login's Power !
        </p>
        <p className=" md:text-xl text-start pt-4">
          Rm login is the process where relationship managers can do KYC-related
          works. Rms can operate the customer’s E-KYC process up to the stock
          segment panel. Also, rms can check the admin panel to verify
          customer’s details.
        </p>
      </div>
      <div className="py-4">
        <p className="sm:text-2xl md:text-3xl font-bold py-4">
          Benefits of RM-Login
        </p>
        <div className="flex sm:flex-col md:flex-row sm:gap-6 md:gap-8 xl:gap-10 cursor-pointer">
          <div className="bg-black md:w-1/2 text-white text-center sm:p-5 xl:p-10 rounded-lg">
            <h2 className="sm:text-xl md:text-2xl xl:text-3xl">
              User benefits{" "}
            </h2>
            <div className="flex justify-around items-center sm:py-2 md:py-4">
              <div className="w-[30%]">
                <img
                  src={User1}
                  className="sm:w-[50px] md:w-[70px] xl:w-[100px] xl:h-[70px] m-auto"
                  alt=""
                />
              </div>
              <p className="w-[70%] text-start md:text-xl xl:text-3xl">
                Easy process for user
              </p>
            </div>
            <div className="flex justify-around items-center sm:py-2 md:py-4">
              <div className="w-[30%]">
                <img
                  src={User2}
                  className="sm:w-[50px] md:w-[70px] xl:w-[100px]  m-auto"
                  alt=""
                />
              </div>
              <p className="w-[70%] text-start md:text-xl xl:text-3xl">
                Minimum details required
              </p>
            </div>
            <div className="flex justify-around items-center sm:py-2 md:py-4">
              <div className="w-[30%]">
                <img
                  src={User3}
                  className="sm:w-[50px] xl:w-[100px] md:h-[70px] m-auto"
                  alt=""
                />
              </div>
              <p className="w-[70%] text-start md:text-xl xl:text-3xl">
                Only image capture & signature required from users
              </p>
            </div>
          </div>
          <div className="bg-black md:w-1/2 text-white text-center sm:p-5 xl:p-10 rounded-lg">
            <h2 className="sm:text-xl md:text-2xl xl:text-3xl">
              Client benefits{" "}
            </h2>
            <div className="flex justify-around items-center sm:py-2 md:py-4">
              <div className="w-[30%]">
                <img
                  src={Client1}
                  className="sm:w-[40px] xl:w-[80px] xl:h-[70px] m-auto"
                  alt=""
                />
              </div>
              <p className="w-[70%] text-start md:text-xl xl:text-3xl">
                Easy & quick lead conversion
              </p>
            </div>
            <div className="flex justify-around items-center sm:py-2 md:py-4">
              <div className="w-[30%]">
                <img
                  src={Client2}
                  className="sm:w-[40px] xl:w-[80px]  m-auto"
                  alt=""
                />
              </div>
              <p className="w-[70%] text-start md:text-xl xl:text-3xl">
                Secure RM-login & user data
              </p>
            </div>
            <div className="flex justify-around items-center sm:py-2 md:py-4">
              <div className="w-[30%]">
                <img
                  src={Client3}
                  className="sm:w-[40px] xl:w-[80px]  m-auto"
                  alt=""
                />
              </div>
              <p className="w-[70%] text-start md:text-xl xl:text-3xl">
                Smooth process
              </p>
            </div>
            <div className="flex justify-around items-center sm:py-2 md:py-4">
              <div className="w-[30%]">
                <img
                  src={Client4}
                  className="sm:w-[40px] xl:w-[70px] xl:h-[70px] m-auto"
                  alt=""
                />
              </div>
              <p className="w-[70%] text-start md:text-xl xl:text-3xl">
                Detailed report in admin panel
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <hr /> */}

      <h3 className="font-bold sm:text-xl md:text-2xl xl:text-3xl">FAQ ?</h3>
      <div className="flex flex-col sm:gap-2 xl:gap-3 py-6 cursor-pointer">
        <button
          onClick={toggleOne}
          className="w-full text-start sm:text-sm md:text-base lg:text-lg font-bold"
        >
          1. How secure is Finovo's RM-LOGIN platform for user data?
        </button>
        {QuesOne && (
          <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg ">
            Finovo's RM-LOGIN employs advanced encryption techniques to ensure
            the utmost security for user data, providing peace of mind to
            customers.
          </p>
        )}
        <button
          onClick={toggleTwo}
          className="w-full text-start sm:text-sm md:text-base lg:text-lg font-bold"
        >
          2. Can I integrate Finovo's RM-LOGIN with my existing software?
        </button>
        {QuesTwo && (
          <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg ">
            Yes, Finovo's RM-LOGIN offers easy integration options for seamless
            functionality with different software systems.
          </p>
        )}
        <button
          onClick={toggleThree}
          className="w-full text-start sm:text-sm md:text-base lg:text-lg font-bold"
        >
          3. Does Finovo's RM-LOGIN offer multi-factor authentication
          capabilities?
        </button>
        {QuesThree && (
          <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg ">
            Absolutely, Finovo's RM-LOGIN provides robust multi-factor
            authentication features to enhance user security and protect against
            unauthorized access.
          </p>
        )}
        <button
          onClick={toggleFour}
          className="w-full text-start sm:text-sm md:text-base lg:text-lg font-bold"
        >
          4. How does Finovo's RM-LOGIN handle password resets and recovery?
        </button>
        {QuesFour && (
          <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg">
            Finovo's RM-LOGIN includes a smooth password reset and recovery
            process, utilizing secure methods to verify user identity and
            protect account information.
          </p>
        )}
        <button
          onClick={toggleFive}
          className="w-full text-start sm:text-sm md:text-base lg:text-lg font-bold"
        >
          5. What kind of customer support does Finovo's RM-LOGIN provide?
        </button>
        {QuesFive && (
          <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg">
            Finovo's RM-LOGIN offers dedicated customer support services to
            assist with any queries or issues, ensuring a smooth user experience
            and prompt resolution of concerns.
          </p>
        )}
      </div>
    </div>
  );
}

export default RmLogin;
