import React, { useEffect, useState, useRef } from "react";
import Logo from "../../assest/footer/finovoLogo.png";
import { ROUTES_NAME } from "../../routers/routeConstants";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./styles.scss";
import Dropdown from "../../component/dropdown";
export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const crossRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleLinkClick = (linkTag) => {
    handleClose();
    navigate(linkTag);
  };
  const handleRedirect = (pageUrl) => {
    window.open(pageUrl);
  };
  const handleOutsideClick = (e) => {
    if (
      menuRef.current &&
      crossRef.current &&
      !menuRef.current.contains(e.target) &&
      !crossRef.current.contains(e.target)
    ) {
      handleClose();
    }
  };
  const handleScroll = () => {
    const scrollThreshold = 700;
    if (window.scrollY > scrollThreshold) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);

      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleAboutClick = () => {
    handleLinkClick(ROUTES_NAME.ABOUT);
  };
  const handleEkycClick = () => {
    handleLinkClick(ROUTES_NAME.EKYC);
  };
  const handleDisClick = () => {
    handleLinkClick(ROUTES_NAME.DIGILOCKERINTEGRATIONSERVICE);
  };
  const handleCalcClick = () => {
    handleLinkClick(ROUTES_NAME.ONLINECALCULATOR);
  };
  const handleCrmClick = () => {  
    handleLinkClick(ROUTES_NAME.CRM)
  }
  const handleWdsClick = () => {
    handleLinkClick(ROUTES_NAME.WEBSITEDESIGNSERVICES)
  }
  const handleClick = (route) => {
    handleLinkClick(route)
  }
  return (
    <>
      <header className="">
        <nav className={`${scrolled ? "scrolled" : ""}`}>
          <ul className="nav-bar flex sm:justify-between md:justify-around">
            <li className="logo">
              <div onClick={() => handleLinkClick(ROUTES_NAME.HOME)}>
                <img className="sm:w-[150px] md:w-[200px]" src={Logo} alt="logo" />
              </div>
            </li>
            <input
              type="checkbox"
              id="check"
              checked={isOpen}
              onChange={() => setIsOpen((prev) => !prev)}
              ref={crossRef}
            />
            <span
              className={`menu font-bold max-[768px]:text-white ${
                isOpen ? "open" : " "
              }`}
              ref={menuRef}
            > 
            <div className="md:flex sm:mt-16 md:mt-0">
              <li className="nav-item dropdown max-[500px]:mt-[40px] w-full p-2 md:mr-12">
                <p
                  className="nav-link dropdown-toggle text-xl"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  Product
                </p>
                <ul className="dropdown-menu cursor-pointer">
                  <li onClick={()=>handleClick(ROUTES_NAME.EKYC)} className="text-md ps-3  pb-2">
                    E-KYC
                  </li>
                  <hr />
                  <li onClick={()=>handleClick(ROUTES_NAME.REKYC)} className="text-md ps-3  pb-2">
                    RE-KYC
                  </li>
                  <hr />
                  <li onClick={()=>handleClick(ROUTES_NAME.SGB)} className="text-md ps-3  pb-2">
                    E-IPO / SGB
                    </li>
                    <hr/>
                  <li onClick={()=>handleClick(ROUTES_NAME.RM_LOGIN)} className="text-md ps-3 pb-2">
                    Rm-Login
                  </li>
                  <hr />
                  <li onClick={()=>handleClick(ROUTES_NAME.ONLINECALCULATOR)} className="text-md ps-3 pb-2 mt-1">
                    Online Calculator
                    </li>
                    <hr />
                  <li onClick={()=>handleClick(ROUTES_NAME.CHATBOT)} className="text-md ps-3 pb-2 mt-1">
                    Chatbot
                    </li>
                </ul>
              </li>
              <li className="nav-item dropdown w-full p-2 md:mr-12">
                <p
                  className="nav-link dropdown-toggle text-xl"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  Solutions
                </p>
                <ul className="dropdown-menu cursor-pointer">
                  <li onClick={()=>handleClick(ROUTES_NAME.DIGILOCKERINTEGRATIONSERVICE)} className="text-sm ps-3 pb-2">
                    Digilocker Integration Services
                  </li>
                  <hr />
                  <li onClick={()=>handleClick(ROUTES_NAME.WEBSITEDESIGNSERVICES)} className="text-sm ps-3 pb-2 mt-1">Website Design services</li>
                  <hr />
                  <li onClick={()=>handleClick(ROUTES_NAME.CRM)} className="text-sm ps-3 pb-2 mt-1">CRM</li>
                </ul>
              </li>
              <li className="cursor-pointer w-full p-2 md:mr-12" onClick={()=>handleClick(ROUTES_NAME.COUNTACTUS)}>
                <p className="text-xl">Contact</p>
              </li>
              <li className="cursor-pointer w-full p-2" onClick={()=>handleClick(ROUTES_NAME.ABOUT)}>
                <p className="text-xl">About</p>
              </li>
              <label htmlFor="check" className="close-menu">
                <i className="fas fa-times"></i>
              </label>
              </div>
            </span>
            <label htmlFor="check" className="open-menu">
              <i className="fas fa-bars"></i>
            </label>
          </ul>
        </nav>
      </header>
    </>
  );
};
