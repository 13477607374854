import React, { useState, useEffect, useRef } from "react";
import Shadow from "../../assest/about/logoShadow.png";
import { ROUTES_NAME } from "../../routers/routeConstants";
import { useNavigate } from "react-router-dom";
import Rekyc from "../../assest/ekyc/rekycPanel.svg";
import Pigi from "../../assest/rekyc/pigi.png";
import Lock from "../../assest/rekyc/lock.png";
import CalLock from "../../assest/rekyc/calLock.png";
import MinusProfile from "../../assest/rekyc/minusprofile.png";
import Profile from "../../assest/rekyc/profile.png";
import TwoProfile from "../../assest/rekyc/twoPeople.png";
import "./styles.css";
import ArrowUp from "../../assest/ekyc/up_arrow.png";
import ArrowDown from "../../assest/ekyc/down_arrow.png";


const mapper = [
  {
    src: Profile,
    text: "Periodic update of customer",
    text2: "information",
  },
  {
    src: CalLock,
    text: "Ensures accuracy and",
    text2: "compliance",
  },
  {
    src: Lock,
    text: "Mitigates risks like fraud and",
    text2: "money laundering",
  },
  {
    src: MinusProfile,
    text: "Account Closure Service :",
    text2: "Terminates accounts",
  },
  {
    src: Pigi,
    text: "Conducted by financial ",
    text2: "institution/service provider",
  },
  {
    src: TwoProfile,
    text: "Triggered by customer/",
    text2: "regulatory factors",
  },
];
const card_mapper = [
  {
    id: 1,
    text: " Sending requests from customers with the most recent data and supporting materials.",
  },
  {
    id: 2,
    text: " Checking new data and documents for compliance and accuracy.",
  },
  {
    id: 3,
    text: " Notifying Customers and updating the system with approved changes.",
  },
  {
    id: 4,
    text: " Risk assessment to improve regulatory compliance and due diligence.",
  },
];
const card_mapper2 = [
  {
    id: 1,
    text: " Use the trading interface to submit a formal request for account closure.",
  },
  {
    id: 2,
    text: " Veriy that every open position is closed or moved to a different account.",
  },
  {
    id: 3,
    text: " Check with the broker to confirm and pay any unpaid amounts and fees.",
  },
  {
    id: 4,
    text: " Get proof of account cancellation and any relevant paperwork for your records.",
  },
];

function Re_Kyc() {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show8, setShow8] = useState(false);
  const [show9, setShow9] = useState(false);
  const [show10, setShow10] = useState(false);
  const [show11, setShow11] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const crossRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);

      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const navigate = useNavigate();
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleLinkClick = (linkTag) => {
    scrollToTop();
    handleClose();
    navigate(linkTag);
  };
  const handleOutsideClick = (e) => {
    if (
      menuRef.current &&
      crossRef.current &&
      !menuRef.current.contains(e.target) &&
      !crossRef.current.contains(e.target)
    ) {
      handleClose();
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleScroll = () => {
    const scrollThreshold = 700;
    if (window.scrollY > scrollThreshold) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleClick = () => {
    handleLinkClick(ROUTES_NAME.HOME);
  };

  return (
    <>
      <div className="sm:p-8 md:p-10 lg:p-12 xl:p-16 relative mt-16">
        <img
          className="absolute top-0 right-0 w-[300px] max-[596px]:w-[200px] max-[319px]:w-[130px]"
          src={Shadow}
          alt=""
        />
        <h1 className="sm:text-2xl lg:text-4xl font-bold mb-10">
          Re-KYC solution for precision and error-free account closure
        </h1>
        <p className="sm:text-sm md:text-base lg:text-xl mb-10">
          Re-KYC is the procedure for periodically updating and confirming
          customer information to ensure correctness and conformity to rules.
          With the help of FINOVO's Re-KYC technology, customer record updates
          are simple. Account closure refers to the process of closing an
          account, which is frequently done at the request of the consumer or
          for regulatory or inactive reasons.
        </p>
        <div className="flex justify-center">
          <img src={Rekyc} alt="re-kyc" />
        </div>
        <p className="text-2xl font-bold my-6">
          What is the need for RE-KYC & a/c closer ?
        </p>
        <p className="mb-2 sm:text-sm md:text-base lg:text-xl">
          It is required to complete KYC to access financial services. Re-KYC
          enables organizations to maintain current records of their personal
          data.
        </p>
        <p className="mb-2 sm:text-sm md:text-base lg:text-xl">
          Account closures can occur for a variety of reasons, including
          financial losses, debt load, market fluctuations, operational
          inefficiencies, insufficient funding, legal troubles, regulatory
          challenges, technical obsolescence, or strategic changes.
        </p>
        <p className="text-2xl text-center font-bold my-12">
          What are the benefits of Finovo's RE-KYC & a/c closer software ?
        </p>
        <div className="grid sm:grid-cols-1 md:grid-cols-2  xl:grid-cols-3 sm:gap-4 md:gap-8 xl:gap-16">
          {mapper.map((item, idx) => (
            <div
              key={idx}
              className="card_color flex justify-between rounded-lg sm:p-4 md:p-8 lg:p-10 xl:p-12 shadow-2xl"
            >
              <div className="flex justify-center items-center sm:w-[20%] lg:w-[30%]">
                <img
                  className="sm:w-[20px] md:w-[40px] lg:w-[50px]"
                  src={item.src}
                  alt="hash"
                />
              </div>
              <div className="flex justify-start items-center sm:w-[80%] lg:w-[70%]">
                <div>
                  <p className="text-white font-bold sm:text-xs md:text-base lg:text-xl text-start">
                    {item.text}
                  </p>
                  <p className="text-white font-bold sm:text-xs md:text-base lg:text-xl text-start">
                    {item.text2}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <p className="text-2xl font-bold mt-12 mb-6">
          Working procedure of RE-KYC & a/c closer
        </p>
        <p className="mb-2 sm:text-sm md:text-base lg:text-xl">
          financial institutions may have different procedures for re-KYC ( know
          Your Customer ) and account termination, however the following is a
          general overview:
        </p>
        <div className="flex sm:flex-col md:flex-row md:justify-between lg:justify-around mt-12">
          <div className=" md:mr-16">
            <p className="font-bold text-2xl text-center mb-8">
              RE-KYC process
            </p>
            <div className="bg-lightBlue sm:px-4 md:px-8 lg:px-16 sm:py-4 md:py-8 rounded-lg">
              {card_mapper.map((item, idx) => (
                <div
                  key={idx.id}
                  className="sm:mb-2 md:mb-4 tracking-wider flex"
                >
                  <p className="md:text-base lg:text-2xl text-white font-bold mr-2">
                    {item.id}.
                  </p>
                  <p className="md:text-base lg:text-2xl text-white ">
                    {item.text}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="md:ml-16">
            <p className="font-bold text-2xl text-center sm:mt-8 md:mt-0 mb-8">
              A/C closer process
            </p>
            <div className="bg-tertiary sm:px-4 md:px-8 lg:px-16 sm:py-4 md:py-8 rounded-lg">
              {card_mapper2.map((item, idx) => (
                <div
                  key={idx.id}
                  className="sm:mb-2 md:mb-4 tracking-wider flex"
                >
                  <p className="md:text-base lg:text-2xl font-bold mr-2">
                    {item.id}.
                  </p>
                  <p className="md:text-base lg:text-2xl ">{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className=" sm:px-10 md:px-20">
        <p className="mb-8 text-xl font-bold">FAQ ?</p>
        <div className="flex justify-between mb-3">
          <p className="w-[80%] sm:text-sm md:text-base lg:text-lg font-bold">
            1. How often is RE-KYC done ?
          </p>
          {show && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow(false)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowUp}
                alt="arrow-up"
              />
            </div>
          )}
          {!show && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow(true)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowDown}
                alt="arrow-down"
              />
            </div>
          )}
        </div>
        {show && (
          <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg">
            RE-KYC is typically done periodically, as required by regulatory
            guidelines and company policies.
          </p>
        )}
        <div className="flex justify-between mb-3">
          <p className="w-[80%] sm:text-sm md:text-base lg:text-lg font-bold">
            2. Is RE-KYC mandatory for all customers ?
          </p>
          {show2 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow2(false)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowUp}
                alt="arrow-up"
              />
            </div>
          )}
          {!show2 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow2(true)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowDown}
                alt="arrow-down"
              />
            </div>
          )}
        </div>
        {show2 && (
          <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg">
            Yes, RE-KYC is mandatory for all customers as part of regulatory
            compliance and risk management practices.
          </p>
        )}
        <div className="flex justify-between mb-3">
          <p className="w-[80%] sm:text-sm md:text-base lg:text-lg font-bold">
            3. What happens if a customer does not complete the RE-KYC process ?
          </p>
          {show3 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow3(false)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowUp}
                alt="arrow-up"
              />
            </div>
          )}
          {!show3 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow3(true)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowDown}
                alt="arrow-down"
              />
            </div>
          )}
        </div>
        {show3 && (
          <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg">
            Failure to complete the RE-KYC process may result in account
            restrictions or closure as per the institution's policies and
            regulatory requirements.
          </p>
        )}
        <div className="flex justify-between mb-3">
          <p className="w-[80%] sm:text-sm md:text-base lg:text-lg font-bold">
            4. Is RE-KYC the same as the initial KYC process ?
          </p>
          {show4 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow4(false)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowUp}
                alt="arrow-up"
              />
            </div>
          )}
          {!show4 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow4(true)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowDown}
                alt="arrow-down"
              />
            </div>
          )}
        </div>
        {show4 && (
          <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg">
            No, RE-KYC is a separate process that focuses on updating and
            confirming existing customer information, while the initial KYC
            process is conducted when a customer first establishes a
            relationship with a financial institution.
          </p>
        )}
        <div className="flex justify-between mb-3">
          <p className="w-[80%] sm:text-sm md:text-base lg:text-lg font-bold">
            5. Can I cancel my account whenever I want ?
          </p>
          {show5 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow5(false)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowUp}
                alt="arrow-up"
              />
            </div>
          )}
          {!show5 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow5(true)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowDown}
                alt="arrow-down"
              />
            </div>
          )}
        </div>
        {show5 && (
          <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg ">
            In most cases, you can request to cancel your account at any time,
            but certain financial institutions may have specific procedures or
            requirements for account closure.
          </p>
        )}
        <div className="flex justify-between mb-3 ">
          <p className="w-[80%] sm:text-sm md:text-base lg:text-lg font-bold">
            6. Are there any fees associated with closing an account ?
          </p>
          {show6 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow6(false)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowUp}
                alt="arrow-up"
              />
            </div>
          )}
          {!show6 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow6(true)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowDown}
                alt="arrow-down"
              />
            </div>
          )}
        </div>
        {show6 && (
          <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg ">
            Some financial institutions may charge a fee for closing an account,
            so it's important to review the terms and conditions of your account
            or contact your institution to inquire about any potential fees.
          </p>
        )}
        <div className="flex justify-between mb-3">
          <p className="w-[80%] sm:text-sm md:text-base lg:text-lg font-bold">
            7. How long does it take to close an account ?
          </p>
          {show7 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow7(false)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowUp}
                alt="arrow-up"
              />
            </div>
          )}
          {!show7 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow7(true)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowDown}
                alt="arrow-down"
              />
            </div>
          )}
        </div>
        {show7 && (
          <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg">
            The timeline for closing an account can vary depending on the
            financial institution and any specific requirements they have. It's
            best to inquire with your institution about the expected timeline
            for account closure.
          </p>
        )}
        <div className="flex justify-between mb-3">
          <p className="w-[80%] sm:text-sm md:text-base lg:text-lg font-bold">
            8. What happens to the remaining balance in my account when it's
            closed ?
          </p>
          {show8 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow8(false)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowUp}
                alt="arrow-up"
              />
            </div>
          )}
          {!show8 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow8(true)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowDown}
                alt="arrow-down"
              />
            </div>
          )}
        </div>
        {show8 && (
          <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg">
            When you close your account, any remaining balance is typically
            disbursed to you in the form of a check or transferred to another
            account of your choosing.
          </p>
        )}
        <div className="flex justify-between mb-3">
          <p className="w-[80%] sm:text-sm md:text-base lg:text-lg font-bold">
            9. Can my account be closed by the financial institution without my
            consent ?
          </p>
          {show9 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow9(false)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowUp}
                alt="arrow-up"
              />
            </div>
          )}
          {!show9 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow9(true)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowDown}
                alt="arrow-down"
              />
            </div>
          )}
        </div>
        {show9 && (
          <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg ">
            Yes, financial institutions may close accounts for various reasons,
            including regulatory requirements, suspected fraud, or if the
            account has been inactive for an extended period of time.
          </p>
        )}
        <div className="flex justify-between mb-3 ">
          <p className="w-[80%] sm:text-sm md:text-base lg:text-lg font-bold">
            10. What should I do after my account has been closed ?
          </p>
          {show10 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow10(false)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowUp}
                alt="arrow-up"
              />
            </div>
          )}
          {!show10 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow10(true)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowDown}
                alt="arrow-down"
              />
            </div>
          )}
        </div>
        {show10 && (
        <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg">
            After your account has been closed, it's important to update any
            automatic payments or direct deposits to ensure they are directed to
            your new account, if applicable. Additionally, you may want to
            securely dispose of any old checks or debit cards associated with
            the closed account.
          </p>
        )}
       <div className="flex justify-between mb-3">
          <p className="w-[80%] sm:text-sm md:text-base lg:text-lg font-bold">11. Can I reopen a closed account ?</p>
          {show11 && (
            <div className="w-[20%] flex justify-end items-center">
            <img
              onClick={() => setShow11(false)}
              className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
              src={ArrowUp}
              alt="arrow-up"
            />
            </div>
          )}
          {!show11 && (
            <div className="w-[20%] flex justify-end items-center">
            <img
              onClick={() => setShow11(true)}
              className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
              src={ArrowDown}
              alt="arrow-down"
            />
            </div>
          )}
        </div>
        {show11 && (
         <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg">
            In some cases, it may be possible to reopen a closed account, but
            this will depend on the policies of the financial institution and
            the circumstances surrounding the closure.
          </p>
        )}
      </div>
    </>
  );
}

export default Re_Kyc;
