import React,{ useState,useEffect,useRef } from "react";
import { ROUTES_NAME } from "../../routers/routeConstants";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import Logo from "../../assest/about/finVector.svg"
import FooterLogo from "../../assest/about/footerLogo.png"
import Shadow from "../../assest/about/logoShadow.png"
import Image from "../../assest/about/image.png"
import Blue from "../../assest/chatbot/blueStar.svg"
import Green from "../../assest/chatbot/greenStar.svg"
import White from "../../assest/chatbot/whiteStar.svg"
import { Helmet } from "react-helmet";
import { Footer } from "../../layout/footer";

const AboutUs = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const crossRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleLinkClick = (linkTag) => {
    scrollToTop();
    handleClose();
    navigate(linkTag);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }; 
  const handleRedirect = (pageUrl) => {
    window.open(pageUrl);
  };
  const handleOutsideClick = (e) => {
    if (
      menuRef.current &&
      crossRef.current &&
      !menuRef.current.contains(e.target) &&
      !crossRef.current.contains(e.target)
    ) {
      handleClose();
    }
  };  
  const handleScroll = () => {
    const scrollThreshold = 700; 
    if (window.scrollY > scrollThreshold) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);

      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleClick = () => {
    handleLinkClick(ROUTES_NAME.HOME);
  }
  return (
    <>
    <Helmet>
    <html lang="en" />
    <title>
    Introduction of ‘FINOVO TECH’.
    </title>
    <meta
      name="description"
      content="India’s one of the best upcoming fintech solution providers helps you to grow your business with top-notch technology."
    />
  </Helmet>
  <h1 className="hidden">Our Goal</h1>
  <h2 className="hidden">Our goal is to provide a solid technology-powered platform that fosters a smooth flow of goods and services that enable advancement.</h2>
      <div className="px-20 pt-10 relative max-[421px]:px-10 mt-16">
        <img className="absolute top-0 right-0 w-[300px] max-[596px]:w-[200px] max-[319px]:w-[130px]" src={Shadow} alt="" />
        <p className="font-semibold text-4xl mb-3 max-[421px]:text-2xl">Our Goal</p>
        <p className="text-2xl sm:text-lg">
          Our goal is to provide a solid technology-powered platform that
          fosters a smooth flow of goods and services that enable advancement.
        </p>
      </div>
      <div className="logo-main-container flex justify-between items-center mt-6 mb-10">
        <div className="pb-6">
          <img src={Logo} alt="" width="60%" />
        </div>
        <div className="w-[60%] text-start max-[421px]:w-[80%]">
          <p className="text-lg">
            Our fundamental values are the main pillars of the finovo culture,
            influencing our actions both internally and externally and guiding
            our vision, goal, and code of conduct.
          </p>
        </div>
      </div>
      <div className="points-container text-2xl text-center bg-grey text-white py-[40px] relative">
        <img src={Blue} alt="" className="absolute sm:w-6 lg:w-10 xxl:w-16 md:right-[30%] sm:right-10" />
        <img src={Blue} alt="" className="absolute sm:w-6 lg:w-10 xxl:w-16 md:top-[60%] md:left-[30%] sm:left-10 " />
        <img src={Green} alt="" className="absolute sm:w-10 lg:w-14 xxl:w-20 md:right-[10%] md:top-[40%] sm:right-5 sm:bottom-10" />
        <img src={Green} alt="" className="absolute sm:w-10 lg:w-14 xxl:w-20 md:bottom-10 md:left-10 sm:hidden md:block" />
        <img src={White} alt="" className="absolute sm:w-6 lg:w-10 xxl:w-16 md:right-[30%] md:bottom-[10%] sm:left-5 sm:bottom-10" />
        <img src={White} alt="" className="absolute sm:w-6 lg:w-10 xxl:w-16 md:left-[30%] sm:hidden md:block" />
        <p className="my-4">Unwavering Morality</p>
        <p className="my-4">Honor & Credence</p>
        <p className="my-4">Success through collaboration</p>
        <p className="my-4">Client orientation</p>
        <p className="my-4">Strive for expertise</p>
      </div>
      <div className="values-container flex gap-7 justify-between pt-[70px] px-[99px]">
        <div className="our-values-container  w-[50%] ">
          <div className="text-7xl font-bold max-[768px]:text-6xl max-[390px]:text-5xl">
          <p className="mb-4">Our</p>
          <p className="text-lightBlue mb-4">unique</p>
          <p className="text-tertiary ">attributes</p>
          </div>
        {/* <div className="h-1 bg-black my-12 max-[900px]:hidden"></div> */}
          {/* <div className="flex justify-center items-center max-[900px]:hidden">
            <img width="170px" src={Image} alt="" />
            <div className="ms-10 font-bold  text-xl max-[1180px]:mt-7">
            <p>Amandeep Singh </p>
            <p>Director , finovo</p>
            </div>
          </div> */}
        </div>
        <div className="values-content-container text-lg w-[50%]">
          <div className="mb-4">
            <p className="font-semibold">Long-term vision</p>
            <p>
              We remain flexible in reaction to shifting customer behavior,
              developing service models, and technological developments thanks
              to our data-driven decision-making and external emphasis.
            </p>
          </div>
          <div className="mb-4">
            <p className="font-semibold">Client-centric viewpoint </p>
            <p>
              We maintain our agility and take advantage of chances in a
              technology environment that is always evolving.
            </p>
          </div>
          <div className="mb-4">
            <p className="font-semibold">Shared passion for victory</p>
            <p>
              These longstanding alliances support both parties' success and
              progress while bolstering our dedication to cooperation and
              innovation.
            </p>
          </div>
          <div className="mb-4">
            <p className="font-semibold">Quality Control and Validation</p>
            <p>
              Throughout our cooperation, you can count on outstanding
              creativity, an experienced team, professionalism, and quick
              turnaround times.
            </p>
          </div>
          <div className="mb-4">
            <p className="font-semibold">Your business is the top priority</p>
            <p>
              Certainly! We at finovo.tech put all of our effort into each
              project because we sincerely care about your business's success.
            </p>
          </div>
         {/* <div className="flex justify-around items-center hidden  max-[900px]:block max-[900px]:flex">
            <img className="mobile-image" width="150px" src={Image} alt="" />
            <div className="mobile-text flex-col font-bold text-xl">
            <p>Amandeep Singh </p>
            <p>Director,finovo</p>
            </div>
          </div>*/}
        </div>
      </div>
      <hr/>
      {/* <Footer/> */}
      {/* <div className="footer-color flex justify-between p-16 flex-wrap-reverse">
        <div onClick={handleClick} className="footer-logo flex justify-start items-center w-[fit]">
          <img className="w-[60%]" src={FooterLogo} alt="" />
        </div>
        <div>
          <p className="text-2xl font-semibold text-white mb-4 max-[400px]:text-xl">Contact Us</p>
          <p className="text-white max-[400px]:text-xs">J-9, Anarkali Road, Krishna Nagar,</p>
          <p className="text-white max-[400px]:text-xs" > New Delhi-110051</p>
          <p className="text-white max-[400px]:text-xs" >support@finovo.tech</p>
          <p className="text-white max-[400px]:text-xs" >sales@finovo.tech</p>
          <p className="text-white max-[400px]:text-xs" >+91-8448963816</p>
        </div>
      </div> */}
      
    </>
  );
};

export default AboutUs;
