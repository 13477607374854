import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES_NAME } from "../../routers/routeConstants";
import Shadow from "../../assest/about/logoShadow.png";
import FooterLogo from "../../assest/about/footerLogo.png";
import Blue from "../../assest/chatbot/blueStar.svg"
import Green from "../../assest/chatbot/greenStar.svg" 
import { Footer } from "../../layout/footer";

function RefundPolicy() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const crossRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleLinkClick = (linkTag) => {
    scrollToTop();
    handleClose();
    navigate(linkTag);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleRedirect = (pageUrl) => {
    window.open(pageUrl);
  };
  const handleOutsideClick = (e) => {
    if (
      menuRef.current &&
      crossRef.current &&
      !menuRef.current.contains(e.target) &&
      !crossRef.current.contains(e.target)
    ) {
      handleClose();
    }
  };
  const handleScroll = () => {
    const scrollThreshold = 700;
    if (window.scrollY > scrollThreshold) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);

      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleClick = () => {
    handleLinkClick(ROUTES_NAME.HOME);
  };
  return (
    <>
      <div className="sm:p-8 md:p-10 lg:p-12 xl:p-16 relative mt-16">
        <img src={Blue} className="absolute sm:w-10 lg:w-14 xxl:w-20 md:right-20 md:top-[50%] sm:right-10" />
        <img src={Green} className="absolute sm:w-10 lg:w-14 xxl:w-20 md:bottom-20 md:right-20 sm:bottom-0 " />
        <img src={Blue} className="absolute sm:w-10 lg:w-10 xxl:w-20  md:left-10 md:bottom-10 sm:bottom-5 sm:right-10" />
        <img
          className="absolute top-0 right-0 w-[300px] max-[596px]:w-[200px] max-[319px]:w-[130px]"
          src={Shadow}
        />
        <p className="sm:text-2xl lg:text-4xl font-bold mb-10">Refund Policy</p>
        <p className="sm:text-lg lg:text-2xl text-start font-bold mb-2">
          Our goal at Finovo Tech is to make sure that our clients have a
          positive experience. We answer questions and concerns about refunds
          and cancellations of subscriptions in our refund policy.
        </p>
        <div className="text-start p-2">
          <li className="mb-2 sm:text-sm lg:text-base md:py-2">
            <b>Cancellation Procedure : </b> To terminate a subscription, go to your account settings or get in touch with customer service
          </li> 
          <li className="mb-2 sm:text-sm lg:text-base md:py-2">
            <b>Refund Requests : </b> Subject to the guidelines outlined in our policy, eligible for refunds.
          </li> 
          <li className="mb-2 sm:text-sm lg:text-base md:py-2">
            <b>Document Access Post-Cancellation : </b> Access is maintained for a predetermined amount of time following cancellation.
          </li> 
          <li className="mb-2 sm:text-sm lg:text-base md:py-2">
            <b>Processing Time for Refunds : </b> After verification, refunds are typically handled in 5-7 business days.
          </li> 
          <li className="mb-2 sm:text-sm lg:text-base md:py-2">
            <b>Refund Exceptions : </b> Our policy specifies the specific circumstances that must be met.
          </li> 
          <li className="mb-2 sm:text-sm lg:text-base md:py-2">
            <b>Restarting Subscriptions : </b> You may always go into your account settings and reactivate canceled subscriptions.
          </li>
          <li className="mb-2 sm:text-sm lg:text-base md:py-2">
            <b>Authorized Payment Methods : </b> Online gateways and credit/debit cards are accepted forms of payment.
          </li> 
          <li className="mb-2 sm:text-sm lg:text-base md:py-2">
            <b>Refund Distribution : </b> Credits are returned using the original payment method, with alternate methods granted based on policy.
          </li>
          <li className="mb-2 sm:text-sm lg:text-base md:py-2">
            <b>Refund Disputes : </b> Get in touch with customer service to have any disagreements transparently resolved.
          </li>
        </div>
      </div>
      <hr />
      {/* <Footer/> */}
      {/* footer */}
      {/* <div className="footer-color flex justify-between p-16 flex-wrap-reverse">
        <div
          onClick={handleClick}
          className="footer-logo flex justify-start items-center w-[fit]"
        >
          <img className="w-[60%]" src={FooterLogo} alt="" />
        </div>
        <div>
          <p className="text-2xl font-semibold text-white mb-4 max-[400px]:text-xl">
            Contact Us
          </p>
          <p className="text-white max-[400px]:text-xs">
            J-9, Anarkali Road, Krishna Nagar,
          </p>
          <p className="text-white max-[400px]:text-xs"> New Delhi-110051</p>
          <p className="text-white max-[400px]:text-xs">support@finovo.tech</p>
          <p className="text-white max-[400px]:text-xs">sales@finovo.tech</p>
          <p className="text-white max-[400px]:text-xs">+91-8448963816</p>
        </div>
      </div> */}
    </>
  );
}

export default RefundPolicy;
