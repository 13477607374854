import React from 'react'
import Chat from "../../assest/chatbot/chatbot.png"
import Blue from "../../assest/chatbot/blueStar.svg"
import Green from "../../assest/chatbot/greenStar.svg"
import { Footer } from '../../layout/footer'
const Chatbot=()=>{
    return (
        <div className='mt-20 w-fit'>
            <h1 className='sm:p-5 md:p-10 xxl:p-20 sm:text-3xl md:text-4xl xxl:text-5xl font-bold'>Chatbots</h1>
            <div className='sm:px-5 md:px-10 xxl:px-20'>
                <p className='sm:text-2xl md:text-2xl xl:text-3xl'>Enter the world of Catboats, where dialogue and AI meet. These digital allies use AI and natural language to solve your problems and respond to your inquiries with ease, resulting in frictionless encounters. With Catboats by your side, embrace a future of simplicity.
                </p>
                <p className='sm:text-2xl md:text-2xl xl:text-3xl'>Turn browsers into consumers by unleashing the power of engagement. Now give fresh oxygen to your website with finovo Chat bot & start chitchatting with your potential customers.
                    Outcomes</p>
                <div className='sm:p-5 flex justify-between gap-10 md:flex-row sm:flex-col relative'>
                    <ul className='list-disc sm:text-lg md:text-xl xl:text-2xl'>
                        <li>Efficiency in Multitasking</li>
                        <li>Adaptability</li>
                        <li>Continuous Learning</li>
                        <li>24/7 Availability</li>
                        <li>Cost Savings</li>
                    </ul>
                    <div className='flex sm:justify-start md:justify-center md:w-[50%] '>
                        <img src={Blue} alt="star"  className='sm:w-10 md:w-14 xl:w-20 absolute sm:right-0' />
                        <img src={Green} alt="star" className='sm:w-10 md:w-14 xl:w-20 absolute sm:bottom-10 sm:right-10 md:bottom-0 md:right-0' />
                        <img src={Blue} alt="star"  className='sm:w-10 md:w-14 xl:w-20 absolute sm:bottom-0 md:left-0' />
                    <img src={Chat} alt='' className='sm:w-[200px] md:w-[300px] xxl:w-[350px]' />
                    </div>
                </div>
            </div>
            <hr className="h-1"/>
            {/* <Footer/> */}
        </div>
    )
}

export default Chatbot