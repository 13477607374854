import React from "react";
import Ai from "../../assest//aiComponemt/aiGrowth.svg";
import Cards from "../aiCard";
import { AI_COMPONENT_MAPPER } from "../mapperConstant/mapperConstant";
import "./styles.scss"

export const AI = () => {
  return (
    <>
      <div className="bg-grey sm:p-12 md:p-24">
        <div className="flex">
          <div className="ai w-[50%] max-[480px]:w-[70%]">
            <div className="ai-main text-5xl font-bold text-white tracking-wider">
              <p>
                Make <span className="ai-text text-blue text-5xl">A</span>
                <span className="ai-text text-green text-5xl">I</span> work
              </p>
              <p className="mt-2">for you.</p>
            </div>
            <div className="text-2xl mt-6 text-white max-[974px]:text-xl max-[704px]:text-sm">
              <p>
                use the power of artificial intelligence to automate your
                product growth for every sale.
              </p>
            </div>
          </div>
          <div className="w-[50%] flex justify-end max-[481px]:hidden">
            <img className="ai-logo" src={Ai} alt="" />
          </div>
        </div>
          <div className=" flex justify-end min-[481px]:hidden">
            <img className="ai-logo " src={Ai} alt="" />
          </div>
        <div className="cards-all cursor-pointer flex flex-wrap justify-around">
          {AI_COMPONENT_MAPPER.map((item) => (
            <Cards
              key={item.title}
              title={item.title}
              src={item.src}
              alt={item.alt}
            />
          ))}
        </div>
      </div>
    </>
  );
};
