import React, { useState } from "react";
import Shadow from "../../assest/about/logoShadow.png";
import Table from "../../assest/sgb/table.png";
import Hash from "../../assest/ekyc/1.png";
import Hash2 from "../../assest/ekyc/2.png";
import Hash3 from "../../assest/ekyc/3.png";
import Hash4 from "../../assest/ekyc/4.png";
import Hash5 from "../../assest/ekyc/5.png";
import Hash6 from "../../assest/ekyc/6.png";
import ArrowUp from "../../assest/ekyc/up_arrow.png";
import One from "../../assest/crm/01.png"
import Three from "../../assest/crm/03.png"
import Two from "../../assest/crm/02.png"
import ArrowDown from "../../assest/ekyc/down_arrow.png";
import { RImg } from "../rImg";

const mapper2 = [
  {
    src: Hash,
  },
  {
    src: Hash2,
  },

  {
    src: Hash3,
  },

  {
    src: Hash4,
  },

  {
    src: Hash5,
  },
  {
    src: Hash6,
  },
];

const Sgb = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show8, setShow8] = useState(false);
  const [show9, setShow9] = useState(false);
  const [show10, setShow10] = useState(false);
  const [show11, setShow11] = useState(false);
  return (
    <>
      <div className="sm:p-8 md:p-10 lg:p-12 xl:p-16 relative mt-16">
        <img
          className="absolute top-0 right-0 w-[300px] max-[596px]:w-[200px] max-[319px]:w-[130px]"
          src={Shadow}
          alt=""
        />
        <p className="sm:text-2xl lg:text-4xl font-bold mb-10">
          Gain a competitive edge with FINOVO's SGB / E-IPO Tool
        </p>
        <p className="sm:text-sm md:text-base lg:text-xl text-start mb-10">
          The term "Sovereign Gold Bond," or simply "SGB," refers to a financial
          instrument backed by the Indian government that enables investors to
          purchase gold in the form of bonds. Without the requirement for real
          gold ownership, it provides interest, safety, and convenience. The
          E-IPO technology from Finovo streamlines the IPO procedure, saving
          clients' and brokerage firms' time. For improved investor involvement,
          it has features including customizable dashboards and real-time
          updates.
        </p>
        <div className="flex justify-center">
          <img className="border shadow-2xl" src={Table} alt="table" />
        </div>
        <p className="sm:text-lg lg:text-2xl text-center font-bold my-20">
          Why choose finovo's SGB E-IPO software ?
        </p>
        <div className="grid grid-cols-2 items-center sm:gap-2 md:gap-4 sm:m-[-25px] md:m-0">
          {mapper2.map((item, idx) => (
            <div key={idx} className="m-auto">
              <img src={item.src} className="md:w-[80%] h-auto mb-6 m-auto" alt="hash" />
            </div>
          ))}
        </div>
        <p className="sm:text-lg lg:text-2xl text-center font-bold my-20">
          Easy steps to apply for the SGB-IPO
        </p>
        <div className="flex gap-10 relative sm:py-4 md:p-4">
          <p className="bg-silver w-full md:w-1/2 text-center text-black font-bold  p-4 text-2xl rounded-xl"> Register</p>
          <img src={One} className="sm:w-[50px] md:w-[100px] absolute sm:top-[-6px] md:top-[-30px]" />
          <p className="sm:hidden md:block md:w-1/2"></p>
        </div>
        <div className="flex gap-10 relative sm:py-4 md:p-4">
          <p className="w-1/2 sm:hidden md:block"></p>
          <img src={Two} className="sm:w-[60px] md:w-[120px] absolute top-[-6px] md:top-[-20px] md:right-[40%]" />
          <p className="bg-blue w-full md:w-1/2 text-center text-white p-4 font-bold  rounded-xl text-2xl"> Select live bid</p>
        </div>
        <div className="flex gap-10 relative sm:py-4 md:p-4">
          <img src={Three} className="sm:w-[60px] md:w-[120px] absolute top-[-6px] md:top-[-20px]" />
          <p className="bg-tertiary w-full md:w-1/2 text-center text-black font-bold rounded-xl  p-4 sm:text-xl md:text-2xl"> Submit the bid details</p>
          <p className="w-1/2 sm:hidden md:block"></p>
        </div>
      </div>
    </>
  );
};

export default Sgb;
