import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES_NAME } from "../../routers/routeConstants";
import Shadow from "../../assest/about/logoShadow.png";
import FooterLogo from "../../assest/about/footerLogo.png"
import Blue from "../../assest/chatbot/blueStar.svg"
import Green from "../../assest/chatbot/greenStar.svg"
import White from "../../assest/chatbot/whiteStar.svg"

function PrivacyPolicy() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const crossRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleLinkClick = (linkTag) => {
    scrollToTop();
    handleClose();
    navigate(linkTag);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleRedirect = (pageUrl) => {
    window.open(pageUrl);
  };
  const handleOutsideClick = (e) => {
    if (
      menuRef.current &&
      crossRef.current &&
      !menuRef.current.contains(e.target) &&
      !crossRef.current.contains(e.target)
    ) {
      handleClose();
    }
  };
  const handleScroll = () => {
    const scrollThreshold = 700;
    if (window.scrollY > scrollThreshold) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);

      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleClick = () => {
    handleLinkClick(ROUTES_NAME.HOME);
  };
  return (
    <>
      <div className="sm:p-5 md:p-10 xl:p-20 relative mt-16">
        <img
          className="absolute top-0 right-0 w-[300px] max-[596px]:w-[200px] max-[319px]:w-[130px]"
          src={Shadow} alt=""
        />
        <p className="sm:text-3xl md:text-4xl xxl:text-5xl font-bold mb-10">
          Privacy Policy
        </p>
        <p className="sm:text-lg md:text-2xl xl:text-3xl text-start font-bold mb-2">
          We at Finovo Tech are dedicated to protecting your privacy. Our
          guidelines delineate:
        </p>
        <div className="text-start mt-3 p-3">
          <li className="mb-2 sm:text-lg lg:text-xl py-2">
            <b>Data Collection : </b> Names, emails, addresses, and usage
            information such as IP addresses and browser activity are among the
            personal information we gather to improve our services.
          </li>
          <li className="mb-2 sm:text-lg lg:text-xl py-2">
            <b>Usage : </b> Data enables us to support, enhance, and maintain
            our services.
          </li>
          <li className="mb-2 sm:text-lg lg:text-xl py-2">
            <b>Security : </b> To safeguard your information, we keep up strong
            security procedures.
          </li>
          <li className="mb-2 sm:text-lg lg:text-xl py-2">
            <b>Your Rights : </b> You are entitled to the access, rectification,
            and deletion of your data. To use these rights, get in touch with
            our data protection officer.
          </li>
          <li className="mb-2 sm:text-lg lg:text-xl">
            <b>Data Retention : </b> The duration of data retention is
            determined by the purpose of the data or by legal requirements.
          </li>
        </div>
        <div className="bg-greyBlack text-white sm:p-5 md:p-10 xl:p-16 rounded-xl flex sm:flex-col md:flex-row md:items-end relative sm:gap-20">
          <img src={Blue} alt="" className="absolute sm:w-10 lg:w-16 xxl:w-20 md:left-[40%]  lg:left-[30%] md:top-10 sm:top-[60%] sm:right-8" />
          <img src={Blue} alt="" className="absolute sm:w-10 lg:w-16 xxl:w-20 md:left-[60%] xxl:left-[70%] md:top-10 sm:bottom-0 sm:right-20" />
          <img src={Green} alt="" className="absolute sm:w-10 lg:w-16 xxl:w-20  md:right-20 md:top-6 sm:right-10" />
          <img src={White} alt="" className="absolute sm:w-7 lg:w-8 xxl:w-10  md:left-[55%] xxl:left-[60%] md:top-[50%] sm:top-[65%]" />
          <div className="md:w-[60%]">
            <p className="sm:text-xl md:text-2xl xxl:text-3xl text-start font-bold mb-2">
              Get in touch with us
            </p>
            <p className="sm:text-lg  xxl:text-xl pt-2">
              Send an email to <a href="support@finovo.tech"><u> support@finovo.tech </u></a> with questions about our privacy policies, and data requests, or to use your data rights.
            </p>
          </div>
          <p className="md:w-[40%] md:text-end sm:text-lg md:text-xl">Please visit our <u>website</u> to learn more.</p>
        </div>
      </div>
      
    </>
  );
}

export default PrivacyPolicy;