import React from "react";
import BlueStar from "../../assest/reviewComponent/blueStar.png"
import WhiteStar from "../../assest/reviewComponent/whiteStar.png"
import GreenStar from "../../assest/reviewComponent/greenStar.png"

function WeAreHere() {
  return (
    <>
      <div className="flex sm:flex-col md:flex-row md:justify-around bg-lightGrey text-white md:p-24 sm:p-12">
        <div className="sm:text-4xl md:text-5xl lg:text-7xl tracking-wider font-bold">
          <p>We're</p>
          <p>here</p>
          <p>for</p>
          <p>you!</p>
        </div>
        <div className="sm:text-xl md:text-2xl lg:text-3xl font-bold flex flex-col justify-center my-16">
          <div>
          <p>It's time for you to relax</p>
          <p>your business is our responsibility.</p>
          {/* <img src={WhiteStar} alt="" className="absolute top-0 left-0"/>
          <img src={BlueStar} alt="" className="absolute top-0 right-0"/>
          <img src={GreenStar} alt="" className="absolute bottom-0 "/> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default WeAreHere;
