import React, { useState, useEffect, useRef } from "react";
import Carousel from "react-multi-carousel";
import Digilocker from "../../assest/product/digilockerFinovo.png";
import Verification from "../../assest/product/aadharPanFinovo.png";
import FooterLogo from "../../assest/about/footerLogo.png";
import { RImg } from "../rImg";
import Shadow from "../../assest/about/logoShadow.png"
import "react-multi-carousel/lib/styles.css";
import { ROUTES_NAME } from "../../routers/routeConstants";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const DigilockerIntegrationServices = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const crossRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleLinkClick = (linkTag) => {
    scrollToTop();
    handleClose();
    navigate(linkTag);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleRedirect = (pageUrl) => {
    window.open(pageUrl);
  };
  const handleOutsideClick = (e) => {
    if (
      menuRef.current &&
      crossRef.current &&
      !menuRef.current.contains(e.target) &&
      !crossRef.current.contains(e.target)
    ) {
      handleClose();
    }
  };
  const handleScroll = () => {
    const scrollThreshold = 700;
    if (window.scrollY > scrollThreshold) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);

      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleClick = () => {
    handleLinkClick(ROUTES_NAME.HOME);
  };
  const CalcResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>
        Finovo’s Digi Locker Integration Service, a smart Digi Locker solution.
        </title>
        <meta
          name="description"
          content="Help to ensure the smooth integration of Digi Locker into your company."
        />
      </Helmet>
      <h1 className="hidden">Digi locker Integration Services</h1>
      <h2 className="hidden">Why not Digi locker integration services by finovo.tech?</h2>
      <div className="sm:p-8 md:p-10 lg:p-12 xl:p-16 relative mt-16">
        <div>
          <img className="absolute top-0 right-0 w-[300px] max-[596px]:w-[200px] max-[319px]:w-[130px]" src={Shadow}/>
        <p className="sm:text-2xl lg:text-4xl font-bold mb-10">
          Digi locker Integration Services
        </p>
        <p className="sm:text-lg lg:text-2xl text-start font-bold mb-2">
          Why not Digi locker integration services by finovo.tech?
        </p>
        <p className="sm:text-sm lg:text-base text-start mb-10">
          We are one of the greatest up-and-coming tech solution providers in
          India, and we can assist you in successfully integrating Digi Locker
          into your business.
        </p>
        </div>
        <div className="flex md:justify-end sm:flex-col md:flex-row mb-2">
          <div className="md:w-[50%] sm:mb-8 md:mb-0 md:mr-10">
            <Carousel
              responsive={CalcResponsive}
              autoPlay={true}
              autoPlaySpeed={1000}
              infinite={true}
            >
              <div className="">
                <RImg
                  externalClassName="flex justify-center"
                  src={Digilocker}
                  alt="digilocker"
                />
              </div>
              <div className="">
                <RImg
                  externalClassName="flex justify-center"
                  src={Verification}
                />
              </div>
            </Carousel>
          </div>
          <div className="text-start md:w-[60%]">
            <p className="font-bold sm:text-lg lg:text-2xl mb-4">
              Trust come from original features. Let's dive into Digi locker
              Integration services. First try us then trust us.
            </p>
            <li className="mb-2 sm:text-sm lg:text-base">
              Digi Locker allows for anywhere access.
            </li>
            <li className="mb-2 sm:text-sm lg:text-base">
              Carry out actions such as e-Sign, EKYC, and more.
            </li>
            <li className="mb-2 sm:text-sm lg:text-base">
              For increased security, easily integrate with your system via API.
            </li>
            <li className="mb-2 sm:text-sm lg:text-base">
              Verify the legitimacy of client documents using Digi Locker.
            </li>
            <li className="mb-2 sm:text-sm lg:text-base">
              User-friendly, valuable to your company, and enhances the client
              experience
            </li>
            <li className="mb-10 sm:text-sm lg:text-base">
              Using digital verification lowers operating expenses.
            </li>
          </div>
        </div>
        <div>
          <p className="font-bold sm:text-lg lg:text-2xl text-start mb-4">
            Use finovo.tech's Digi Locker Integration Solution to Simplify Your
            Digital Lockers.
          </p>
          <div className="md:flex gap-2 text-center">
            <div className="sm:mb-6 bg-grey text-white md:mb-0 md:w-[33%] h-[20vh] border-2 sm:p-1 md:p-3 rounded-3xl">
              <p className="uppercase  font-bold mt-4 mb-3 sm:text-sm md:text-base">
                Digital Sign Facility
              </p>
              <p className="md:text-sm sm:text-xs">
                Using a computer or mobile device, electronic signature
                capabilities or services enable both individuals and
                organizations to digitally sign documents.
              </p>
            </div>
            <div className="sm:mb-6 md:mb-0 md:w-[33%] h-[20vh] text-white bg-grey border-2 sm:p-1 md:p-3 rounded-3xl">
              <p className="uppercase font-bold mt-4 mb-3 sm:text-sm md:text-base">
                Verification Stream
              </p>
              <p className="md:text-sm sm:text-xs">
                Generally used to describe the procedure of real-time data or
                information verification as it is being received or processed
              </p>
            </div>
            <div className="md:w-[33%] h-[20vh] text-white bg-grey border-2 sm:p-1 md:p-3 rounded-3xl">
              <p className="uppercase font-bold mt-4 mb-3 sm:text-sm md:text-base">
                Securely Share Documents
              </p>
              <p className="sm:text-xs md:text-sm">
                Improved data security and document management.
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr/>
      {/* footer */}
      {/* <div className="footer-color flex justify-between p-16 flex-wrap-reverse">
        <div
          onClick={handleClick}
          className="footer-logo flex justify-start items-center w-[fit]"
        >
          <img className="w-[60%]" src={FooterLogo} alt="" />
        </div>
        <div>
          <p className="text-2xl font-semibold text-white mb-4 max-[400px]:text-xl">
            Contact Us
          </p>
          <p className="text-white max-[400px]:text-xs">
            J-9, Anarkali Road, Krishna Nagar,
          </p>
          <p className="text-white max-[400px]:text-xs"> New Delhi-110051</p>
          <p className="text-white max-[400px]:text-xs">support@finovo.tech</p>
          <p className="text-white max-[400px]:text-xs">sales@finovo.tech</p>
          <p className="text-white max-[400px]:text-xs">+91-8448963816</p>
        </div>
      </div> */}
    </>
  );
};

export default DigilockerIntegrationServices;
