import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES_NAME } from "../../routers/routeConstants";
import WeProvide from "../../assest/provide/weProvide.png";
import Chat from "../../assest/provide/chat.png";
import Computer from "../../assest/provide/computer.png";
import Profile from "../../assest/provide/profile.png";
import Message from "../../assest/provide/message.png";
import Calculator from "../../assest/provide/calculator.png";
import "./styles.scss";
export const Provide = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const crossRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleLinkClick = (linkTag) => {
    scrollToTop();
    handleClose();
    navigate(linkTag);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleRedirect = (pageUrl) => {
    window.open(pageUrl);
  };
  const handleOutsideClick = (e) => {
    if (
      menuRef.current &&
      crossRef.current &&
      !menuRef.current.contains(e.target) &&
      !crossRef.current.contains(e.target)
    ) {
      handleClose();
    }
  };
  const handleScroll = () => {
    const scrollThreshold = 700;
    if (window.scrollY > scrollThreshold) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);

      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleKycClick = () => {
    handleLinkClick(ROUTES_NAME.EKYC);
  };
  const handleDigilockerClick = () => {
    handleLinkClick(ROUTES_NAME.DIGILOCKERINTEGRATIONSERVICE);
  };
  const handleCalcClick = () => {
    handleLinkClick(ROUTES_NAME.ONLINECALCULATOR);
  };
  const handleCrmClick = () => {
    handleLinkClick(ROUTES_NAME.CRM);
  };

  return (
    <div className="provide-container">
      <div>
        <div className="z-0 flex justify-center">
          <img className="provide-image" width="90%" src={WeProvide} alt="" />
        </div>
        <div className="weprovide-text text-9xl text-white text-center z-10 font-bold">
          <p>We Provide</p>
        </div>
      </div>
      <div className="our-services flex justify-center flex-wrap max-[500px]:gap-8 md:mt-[100px] text-white text-center max-[428px]:mt-[35px] cursor-pointer">
        <div className="w-[118px] md:mx-12 max-[428px]:mx-4 max-[428px]:w-[68px]">
          <img className="our-service grow" src={Chat} alt="" />
          <p className="mt-8">Chatbot</p>
          <span className="mb-8 font-bold uppercase text-blue">
            (Coming Soon)
          </span>
        </div>
        <div
          onClick={handleKycClick}
          className="w-[118px] md:mx-12 cursor-pointer max-[428px]:mx-4 max-[428px]:w-[68px]"
        >
          <img className="our-service grow" src={Computer} alt="" />
          <p className="my-12">e-KYC / re-KYC</p>
        </div>
        <div
          onClick={handleCalcClick}
          className="w-[118px] md:mx-12 cursor-pointer max-[428px]:mx-4 max-[428px]:w-[68px]"
        >
          <img className="our-service grow" src={Calculator} alt="" />
          <p className="my-8">Online Calculator</p>
        </div>
        <div
          onClick={handleCrmClick}
          className="w-[118px] md:mx-12 max-[428px]:mx-4 max-[428px]:w-[68px]"
        >
          <img className="our-service grow" src={Message} alt="" />
          <p className="mt-12">CRM</p>
        </div>
        <div
          onClick={handleDigilockerClick}
          className="w-[118px] md:mx-12 cursor-pointer max-[428px]:mx-4 max-[428px]:w-[68px]"
        >
          <img className="our-service grow" src={Profile} alt="" />
          <p className="my-14">DigiLocker Integration</p>
        </div>
      </div>
    </div>
  );
};
