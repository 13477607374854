import React from "react";
import Shadow from "../../assest/about/logoShadow.png";
const DIgital_E_Sign = () => {
  return (
    <>
      <div className="sm:p-8 md:p-10 lg:p-12 xl:p-16 relative mt-16">
        <img
          className="absolute top-0 right-0 w-[300px] max-[596px]:w-[200px] max-[319px]:w-[130px]"
          src={Shadow}
        />
        <p className="sm:text-2xl lg:text-4xl font-bold mb-10">
          Digital e-sign
        </p>
        <p className="sm:text-lg lg:text-2xl font-bold mb-4">
          Finovo Aadhar e-Sign in now available:
        </p>
        <p className="sm:text-sm md:text-base lg:text-xl text-start mb-10">
          Adhaar card unlocks convenience and security by enabling remote
          document signing. No physical presence is necessary. Efficient,
          secure, paperless document signing is advantageous to both businesses
          and people. Break away from conventional constraints and elevate your
          processes using FINOVO's digital signatures.
        </p>
        <p className="sm:text-lg lg:text-2xl font-bold mb-4">
          Why finovo aadhar e-sign?
        </p>
        <p className="sm:text-sm md:text-base lg:text-xl text-start mb-10">
          In India, finovo.tech provides a trustworthy online platform for
          electronic signatures, displacing manual procedures with effective
          digital ones. Our solution allows customers to sign documents
          digitally and increases transaction efficiency while guaranteeing
          legal compliance and authenticity.
        </p>
        <ul className="list-disc">
            <li className="ml-4 mb-3">Efficient digital process.</li>
            <li className="ml-4 mb-3">Customer document signing.</li>
            <li className="ml-4 mb-3">Improved transaction speed.</li>
            <li className="ml-4 mb-3">Legal compliance and authenticity.</li>
            <li className="ml-4 mb-3">Streamlined, legally assured processes.</li>
        </ul>
      </div>
    </>
  );
};

export default DIgital_E_Sign;
