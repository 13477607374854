const ROUTES_NAME = {
  HOME: "/",
  ABOUT: "about/",
  EKYC: "ekyc/",
  CRM: "crm/",
  WEBSITEDESIGNSERVICES: "website-design-services",
  ONLINECALCULATOR: "online-calculator/",
  DIGILOCKERINTEGRATIONSERVICE: "digilocker-integration-service/",
  TERMSANDCONDITION: "terms-and-conditon/",
  COUNTACTUS: "contact/",
  PRIVACYPOLICY: "privacy-policy",
  REFUNDPOLICY: "refund-policy",
  CHATBOT: "chatbot",
  DIGITAL_E_SIGN: "digital-e-sign",
  SGB: "sgb",
  REKYC: "re-kyc",
  RM_LOGIN:"rm-login"
};
export { ROUTES_NAME };
