import React, { useState } from 'react';
import { QUALIFICATION_DETAILS_CONSTANTS } from "../../mapperConstant"
function Dropdown() {
  const [isOpen, setIsOpen] = useState("Solution");

  function toggleDropdown(event) {
    const newValue = event.target.value;
    setIsOpen(newValue);
  }
  return (
    <div>
    <div>
      <label>
        <select
          className="w-56 text-black rounded px-2 border-2"
          value={isOpen}
          name="education"
          onChange={toggleDropdown}
        >
          {QUALIFICATION_DETAILS_CONSTANTS.map((item) => (
            <option key={item.key} value={item.key}>
              {item.title}
            </option>
          ))}
        </select>
      </label>
    </div>
  </div>
  );
}

export default Dropdown;
