import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES_NAME } from "../../routers/routeConstants";
import Logo from "../../assest/footer/finovoLogo.png";
import { RImg } from "../../component/rImg";
import "./styles.scss";
import Location from "../../assest/footer/locationIcon.png";
import Email from "../../assest/footer/addressIcon.png";
import { ImFacebook2 } from "react-icons/im";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { BsFillThreadsFill } from "react-icons/bs";
import { FaInstagramSquare } from "react-icons/fa";
export const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const crossRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleLinkClick = (linkTag) => {
    scrollToTop();
    handleClose();
    navigate(linkTag);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleRedirect = (pageUrl) => {
    window.open(pageUrl);
  };
  const handleOutsideClick = (e) => {
    if (
      menuRef.current &&
      crossRef.current &&
      !menuRef.current.contains(e.target) &&
      !crossRef.current.contains(e.target)
    ) {
      handleClose();
    }
  };
  const handleScroll = () => {
    const scrollThreshold = 700;
    if (window.scrollY > scrollThreshold) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);

      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleClick = (route) => {
    handleLinkClick(route);
  };
  return (
    <>
      <div className="footer-container">
        <div className="footer-left-content p-12 flex justify-start flex-col max-[744px]:p-8">
          <div className="flex justify-start">
            <div
              onClick={() => handleClick(ROUTES_NAME.HOME)}
              className="footer-icon"
            >
              <RImg innerImgClassName=" w-[270px]" src={Logo} alt="" />
            </div>
          </div>
          <div className="w-full border-1 sm:block md:hidden"></div>
          <div className="footer-mobile-view">
            <div className="font-bold text-base my-4">
              <p>Complete automation , no manual</p>
              <p className="mt-2 text-xs">
                Finovo helps you grow your business with top-notch technology.
              </p>
            </div>
            <div className=" max-[740px]:ml-4 text-sm">
              <div className="text-sm text-start">
                <div className="mt-6 mb-2">
                  <RImg src={Location} alt="location" />
                </div>
                <p>
                  J-9, Anarkali Road, Krishna Nagar, <br />
                  New Delhi-110051
                </p>
                <div className="mt-6 mb-2">
                  <RImg src={Email} alt="location" />
                </div>
                <p>support@finovo.tech</p>
                <p>sales@finovo.tech</p>
                <p>+91-8448963816</p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-list p-12 flex justify-around max-[744px]:p-8">
          <div className="sm:ml-2 md:mr-4 cursor-pointer">
            <ul>
              <p className="font-bold sm:mb-3 md:mb-6">Products</p>
              <li onClick={() => handleClick(ROUTES_NAME.EKYC)}>KYC</li>
              <li onClick={() => handleClick(ROUTES_NAME.ONLINECALCULATOR)}>
                Online Calculator
              </li>
            </ul>
          </div>
          <div className="sm:mx-2 md:mr-4 cursor-pointer">
            <ul>
              <p className="font-bold sm:mb-3 md:mb-6">Solutoins</p>
              <li
                onClick={() =>
                  handleClick(ROUTES_NAME.DIGILOCKERINTEGRATIONSERVICE)
                }
              >
                Digilocker Integration
                <br /> Services
              </li>
              <li
                onClick={() => handleClick(ROUTES_NAME.WEBSITEDESIGNSERVICES)}
              >
                Website Design <br /> Services
              </li>
              <li onClick={() => handleClick(ROUTES_NAME.CRM)}>CRM</li>
            </ul>
          </div>
          <div className="sm:mx-2 md:mr-4 cursor-pointer">
            <ul>
              <p className="font-bold  sm:mb-3 md:mb-6">Finovo</p>
              <li onClick={() => handleClick(ROUTES_NAME.ABOUT)}>About</li>
              <li onClick={() => handleClick(ROUTES_NAME.COUNTACTUS)}>
                Contact Us
              </li>
            </ul>
          </div>
          <div className="sm:mx-2 md:mr-4 cursor-pointer">
            <ul>
              <p className="font-bold  sm:mb-3 md:mb-6">Policy</p>
              <li onClick={() => handleClick(ROUTES_NAME.PRIVACYPOLICY)}>
                Privacy Policy
              </li>
              <li onClick={() => handleClick(ROUTES_NAME.TERMSANDCONDITION)}>
                Terms and Condition
              </li>
              <li onClick={() => handleClick(ROUTES_NAME.REFUNDPOLICY)}>
                Refund Policy
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mx-6 mb-3  cursor-pointer md:hidden">
            <ul className="flex justify-between">
              <li className="m-0">
                <a href="https://www.facebook.com/profile.php?id=61555182434059">
                  <ImFacebook2 style={{ width: "20px", height: "30px" }} />
                </a>
              </li>
              <li className="m-0">
                <a href="https://www.linkedin.com/company/finovo-tech">
                  <FaLinkedin style={{ width: "20px", height: "30px" }} />
                </a>
              </li>
              <li className="m-0">
                <a href="https://www.threads.net/@finovotech">
                  <BsFillThreadsFill
                    style={{ width: "20px", height: "30px" }}
                  />
                </a>
              </li>
              <li className="m-0">
                <a href="https://twitter.com/Finovotech">
                  <FaSquareXTwitter style={{ width: "20px", height: "30px" }} />
                </a>
              </li>
              <li className="m-0">
                <a href="https://www.instagram.com/finovotech/">
                  <FaInstagramSquare
                    style={{ width: "20px", height: "30px" }}
                  />
                </a>
              </li>
            </ul>
          </div>
      <div className="text-center font-bold mb-5">
        <p>Copyright © 2024 - Finovo Tech </p>
      </div>
    </>
  );
};
