import "./styles.scss";

const Cards = (props) => {
  const { src, title, alt } = props;
  return (
    <div className="cards-container w-[16rem] border border-white bg-green rounded-3xl my-8">
      <div className="cards-image-container flex h-[130px] bg-black rounded-t-3xl justify-center p-6">
        <img className="cards-image grow w-[100px]" src={src} alt={alt} />
      </div>
      <p className="cards-title font-bold rounded-3xl text-2xl p-8 text-center max-[552px]:p-6">
        {title}
      </p>
    </div>
  );
};
export default Cards;
