import React, { useState, useEffect, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ROUTES_NAME } from "../../routers/routeConstants";
import { useNavigate } from "react-router-dom";
import FooterLogo from "../../assest/about/footerLogo.png";
import WhiteBitrix from "../../assest/product/whiteBitrixImage.png";
import DarkBitrix from "../../assest/product/darkBitrixImage.png";
import { RImg } from "../rImg";
import CrmImage from "../../assest/crm/CRM_1.png";
import CrmTool from "../../assest/crm/crmTool.png";
import CrmCall from "../../assest/crm/crmCall.png";
import CrmContact from "../../assest/crm/crmContact.png";
import CrmTime from "../../assest/crm/crmTime.png";
import Shadow from "../../assest/about/logoShadow.png"
import { Helmet } from "react-helmet";

function Crm() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const crossRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleLinkClick = (linkTag) => {
    scrollToTop();
    handleClose();
    navigate(linkTag);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleRedirect = (pageUrl) => {
    window.open(pageUrl);
  };
  const handleOutsideClick = (e) => {
    if (
      menuRef.current &&
      crossRef.current &&
      !menuRef.current.contains(e.target) &&
      !crossRef.current.contains(e.target)
    ) {
      handleClose();
    }
  };
  const handleScroll = () => {
    const scrollThreshold = 700;
    if (window.scrollY > scrollThreshold) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);

      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleClick = () => {
    handleLinkClick(ROUTES_NAME.HOME);
  };
  const CalcResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
     <Helmet>
    <html lang="en" />
    <title>
    Finovo’s CRM, always ahead in all types of marketing solutions.
    </title>
    <meta
      name="description"
      content="Finovo’s CRM, a one-stop digital ‘CUSTOMER RELATIONSHIP MANAGEMENT’ solution includes sales, client administration, customer service & marketing automation etc.
"
    />
  </Helmet>
  <h1 className="hidden">Why finovo's CRM is important?</h1>
  <h2 className="hidden">Your one-stop digital sales solution, Bitrix24 CRM, includes client administration, customer service, and marketing automation.</h2>
      <div className="sm:p-8 md:p-10 lg:p-12 xl:p-16 relative mt-16">
        <div>
          <img className="absolute top-0 right-0 w-[300px] max-[596px]:w-[200px] max-[319px]:w-[130px]" src={Shadow}/>
        <p className="sm:text-2xl lg:text-4xl font-bold mb-10">CRM</p>
        <p className="sm:text-lg lg:text-2xl text-center font-bold mb-2">
          Why finovo's CRM is important?
        </p>
        <p className="sm:text-lg lg:text-xl text-center mb-10">
          Your one-stop digital sales solution, Bitrix24 CRM, includes client
          administration, customer service, and marketing automation.
        </p>
        </div>
        <div className="md:flex md:justify-around mt-10">
          <div className="flex justify-center items-center">
            <div>
              <p className="sm:text-lg lg:text-2xl text-start font-bold mb-2">
                Why Bitrix24 CRM service is one step ahead from others?
              </p>
              <p className="sm:text-lg lg:text-xl text-start mb-10">
                <li>Alternate SaaS tools</li>
                <li>Unlimited, free usage forever</li>
                <li>Simple data movement</li>
                <li>Effortless app integration</li>
                <li>12M+ consumers globally trust it</li>
                <li>Predictable, no-cost pricing</li>
              </p>
            </div>
          </div>
            <div className="sm:w-full md:w-[45%] m:mb-8 md:mb-0 md:mr-10">
              <Carousel
                responsive={CalcResponsive}
                autoPlay={true}
                autoPlaySpeed={1000}
                infinite={true}
              >
                <div className="">
                  <RImg
                    externalClassName="flex justify-center"
                    innerImgClassName="lg:h-[50vh]"
                    src={WhiteBitrix}
                    alt="bitrix"
                  />
                </div>
                <div className="">
                  <RImg
                    externalClassName="flex justify-center"
                    innerImgClassName="lg:h-[50vh] "
                    src={DarkBitrix}
                    alt="bitrix"
                  />
                </div>
              </Carousel>
            </div>
        </div>
        <div className="md:flex md:justify-around mt-10">
          <div className="md:w-[40%]">
            <RImg src={CrmImage} alt="crm" />
          </div>
          <div className="md:w-[40%] flex justify-center items-center">
            <div className="md:text-xs lg:text-sm xs-sm">
              <p className="font-bold mb-2">Lead acquisition</p>
              <p>
                Generate leads via multiple channels: web forms, live chats,
                phone calls, or social media, and have them added to your CRM
                automatically.
              </p>
              <p className="font-bold my-2">Lead management</p>
              <p>
                Use our built-in lead scoring tool to see which prospects your
                sales reps need to focus on and assign them automatically.
              </p>
              <p className="font-bold my-2">Sales automation</p>
              <p>
                Dozens of sales and marketing automation rules and triggers to
                save you time and money.
              </p>
            </div>
          </div>
        </div>
        <div className="md:flex md:justify-around mt-10 sm:flex-wrap-reverse">
          <div className="md:w-[40%] md:hidden">
            <RImg src={CrmTool} alt="crm" />
          </div>
          <div className="md:w-[40%] flex justify-center items-center">
            <div className="md:text-xs lg:text-sm xs-sm">
              <p className="font-bold mb-2">Workload management</p>
              <p>
                Monitor the project activity, receive work reports, and
                distribute workload more evenly..
              </p>
              <p className="font-bold my-2">Task templates & automation</p>
              <p>
                Save time on recurring tasks by automating them (e.g., “daily
                SEO report”).
              </p>
              <p className="font-bold my-2">Visual project management</p>
              <p>
                Printable Gantt charts, customizable Kanban boards, and plenty
                of other visual representations for your projects.
              </p>
            </div>
          </div>
          <div className="sm:mt-10 md:w-[40%] sm:hidden md:block">
            <RImg src={CrmTool} alt="crm" />
          </div>
        </div>
        <div className="md:flex md:justify-around mt-10">
          <div className="md:w-[40%]">
            <RImg src={CrmCall} alt="crm" />
          </div>
          <div className="md:w-[40%] flex justify-center items-center">
            <div className="md:text-xs lg:text-sm xs-sm">
              <p className="font-bold mb-2">HD video calls & conferences</p>
              <p>
                Create conferences for up to 48 participants, talk without any
                time limit, record your calls, and change backgrounds.
              </p>
              <p className="font-bold my-2">Chats</p>
              <p>
                Private and group chats - a quick and easy way to discuss ideas
                and share files.
              </p>
              <p className="font-bold my-2">Worktime tracking</p>
              <p>
                Your team can start/pause/finish their working day using an
                online time clock built into Bitrix24.
              </p>
            </div>
          </div>
        </div>
        <div className="md:flex md:justify-around mt-10 sm:flex-wrap-reverse">
          <div className="md:w-[40%] sm:mb-2 md:hidden">
            <RImg src={CrmContact} alt="crm" />
          </div>
          <div className="md:w-[40%] flex justify-center items-center">
            <div className="md:text-xs lg:text-sm xs-sm">
              <p className="font-bold mb-2">Telephony</p>
              <p>
                Set up your own call center with smart routing and call
                tracking.
              </p>
              <p className="font-bold my-2">Live chat</p>
              <p>
                Plenty of advanced settings, including customizable chatbots,
                canned replies, and more.
              </p>
              <p className="font-bold my-2">Contact forms</p>
              <p>
                Customizable contact, feedback and callback request forms for
                your website.
              </p>
            </div>
          </div>
          <div className="sm:mt-10 md:w-[40%] sm:hidden md:block">
            <RImg src={CrmContact} alt="crm" />
          </div>
        </div>
        <div className="md:flex md:justify-around mt-10">
          <div className="md:w-[40%]">
            <RImg src={CrmTime} alt="crm" />
          </div>
          <div className="md:w-[40%] flex justify-center items-center">
            <div className="md:text-xs lg:text-sm xs-sm">
              <p className="font-bold mb-2">Online time clock & reports</p>
              <p>
                Track working hours, collect work reports, and manage employee
                attendance.
              </p>
              <p className="font-bold my-2">HR automation</p>
              <p>
                Automate your business processes and workflows to save time and
                human resources.
              </p>
              <p className="font-bold my-2">People data and analytics</p>
              <p>
                Easy and intuitive employee performance reporting complete with
                employee directory.
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr/>
      {/* footer */}
      {/* <div className="footer-color flex justify-between p-16 flex-wrap-reverse">
        <div
          onClick={handleClick}
          className="footer-logo flex justify-start items-center w-[fit]"
        >
          <img className="w-[60%]" src={FooterLogo} alt="" />
        </div>
        <div>
          <p className="text-2xl font-semibold text-white mb-4 max-[400px]:text-xl">
            Contact Us
          </p>
          <p className="text-white max-[400px]:text-xs">
            J-9, Anarkali Road, Krishna Nagar,
          </p>
          <p className="text-white max-[400px]:text-xs"> New Delhi-110051</p>
          <p className="text-white max-[400px]:text-xs">support@finovo.tech</p>
          <p className="text-white max-[400px]:text-xs">sales@finovo.tech</p>
          <p className="text-white max-[400px]:text-xs">+91-8448963816</p>
        </div>
      </div> */}
    </>
  );
}

export default Crm;
