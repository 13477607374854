import React, { useState, useEffect, useRef,useCallback } from "react";
import Carousel from "react-multi-carousel";
import { RImg } from "../rImg";
import Margin from "../../assest/product/marginCalc.png";
import Equity from "../../assest/product/equityCalc.png";
import FooterLogo from "../../assest/about/footerLogo.png";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { ROUTES_NAME } from "../../routers/routeConstants";
import { Helmet } from "react-helmet";
import Shadow from "../../assest/about/logoShadow.png"
import axios from "axios";

export const OnlineCalculator = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const crossRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleLinkClick = (linkTag) => {
    scrollToTop();
    handleClose();
    navigate(linkTag);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleRedirect = (pageUrl) => {
    window.open(pageUrl);
  };
  const handleOutsideClick = (e) => {
    if (
      menuRef.current &&
      crossRef.current &&
      !menuRef.current.contains(e.target) &&
      !crossRef.current.contains(e.target)
    ) {
      handleClose();
    }
  };
  const handleScroll = () => {
    const scrollThreshold = 700;
    if (window.scrollY > scrollThreshold) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);

      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const CalcResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const handleClick = () => {
    handleLinkClick(ROUTES_NAME.HOME);
  };
  const [messageDel, setDelMessage] = useState("");
  const [messageInt, setIntMessage] = useState("");
  const [messageFut, setFutMessage] = useState("");
  const [messageOpt, setOptMessage] = useState("");
  const handleWheel = (e) => {
    e.target.blur();
  };

  const [inputValue, setInputValue] = useState({
    buyPrice: "",
    sellPrice: "",
    quantity: "",
  });

  const [input2Value, setInput2Value] = useState({
    buyPrice: "",
    sellPrice: "",
    quantity: "",
  });
  const [input3Value, setInput3Value] = useState({
    buyPrice: "",
    sellPrice: "",
    quantity: "",
  });
  const [input4Value, setInput4Value] = useState({
    buyPrice: "",
    sellPrice: "",
    quantity: "",
  });

  const [delData, setDelData] = useState({
    turnOver: 0,
    brokerageCharges: 0,
    STT: 0,
    transactionCharge: 0,
    IPFT: 0,
    SEBI: 0,
    stampCharge: 0,
    clearingCharge: 0,
    GST_Charges: 0,
    Grand_Total: 0,
  });
  const [intData, setIntData] = useState({
    turnOver: 0,
    brokerageCharges: 0,
    STT: 0,
    transactionCharge: 0,
    IPFT: 0,
    SEBI: 0,
    stampCharge: 0,
    clearingCharge: 0,
    GST_Charges: 0,
    Grand_Total: 0,
  });
  const [futData, setFutData] = useState({
    turnOver: 0,
    brokerageCharges: 0,
    STT: 0,
    transactionCharge: 0,
    IPFT: 0,
    SEBI: 0,
    stampCharge: 0,
    clearingCharge: 0,
    GST_Charges: 0,
    Grand_Total: 0,
  });
  const [optData, setOptData] = useState({
    turnOver: 0,
    brokerageCharges: 0,
    STT: 0,
    transactionCharge: 0,
    IPFT: 0,
    SEBI: 0,
    stampCharge: 0,
    clearingCharge: 0,
    GST_Charges: 0,
    Grand_Total: 0,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Parse quantity value as integer
    const parsedValue = name === "quantity" ? parseInt(value, 10) : value;

    if (parsedValue < 0) {
      setDelMessage("Value Can't be Negative");
    } else {
      setDelMessage(""); // Clear message when valid input is provided
      setInputValue({
        ...inputValue,
        [name]: parsedValue,
      });
    }
  };

  const handleChangeI = (event) => {
    const { name, value } = event.target;
    const parsedValue = name === "quantity" ? parseInt(value, 10) : value;
    if (parsedValue < 0) {
      setIntMessage("Value Can't be Negative");
    } else {
      setIntMessage(""); // Clear message when valid input is provided
      setInput2Value({
        ...input2Value,
        [name]: parsedValue,
      });
    }
  };

  const handleChangeF = (event) => {
    const { name, value } = event.target;
    const parsedValue = name === "quantity" ? parseInt(value, 10) : value;
    if (parsedValue < 0) {
      setFutMessage("Value Can't be Negative");
    } else {
      setFutMessage(""); // Clear message when valid input is provided
      setInput3Value({
        ...input3Value,
        [name]: parsedValue,
      });
    }
  };

  const handleChangeO = (event) => {
    const { name, value } = event.target;
    const parsedValue = name === "quantity" ? parseInt(value, 10) : value;
    if (parsedValue < 0) {
      setOptMessage("Value Can't be Negative");
    } else {
      setOptMessage(""); // Clear message when valid input is provided
      setInput4Value({
        ...input4Value,
        [name]: parsedValue,
      });
    }
  };

  //clearing State
  const clearDelState = () => {
    setInputValue({
      buyPrice: "",
      sellPrice: "",
      quantity: "",
    });
    setDelMessage("");
    setDelData({
      turnOver: 0,
      brokerageCharges: 0,
      STT: 0,
      transactionCharge: 0,
      IPFT: 0,
      SEBI: 0,
      stampCharge: 0,
      clearingCharge: 0,
      GST_Charges: 0,
      Grand_Total: 0,
    });
  };

  const clearIntState = () => {
    setInput2Value({
      buyPrice: "",
      sellPrice: "",
      quantity: "",
    });
    setIntData({
      turnOver: 0,
      brokerageCharges: 0,
      STT: 0,
      transactionCharge: 0,
      IPFT: 0,
      SEBI: 0,
      stampCharge: 0,
      clearingCharge: 0,
      GST_Charges: 0,
      Grand_Total: 0,
    });
  };

  const clearFutState = () => {
    setInput3Value({
      buyPrice: "",
      sellPrice: "",
      quantity: "",
    });
    setFutData({
      turnOver: 0,
      brokerageCharges: 0,
      STT: 0,
      transactionCharge: 0,
      IPFT: 0,
      SEBI: 0,
      stampCharge: 0,
      clearingCharge: 0,
      GST_Charges: 0,
      Grand_Total: 0,
    });
  };

  const clearOptState = () => {
    setInput4Value({
      buyPrice: "",
      sellPrice: "",
      quantity: "",
    });
    setOptData({
      turnOver: 0,
      brokerageCharges: 0,
      STT: 0,
      transactionCharge: 0,
      IPFT: 0,
      SEBI: 0,
      stampCharge: 0,
      clearingCharge: 0,
      GST_Charges: 0,
      Grand_Total: 0,
    });
    // setMessage("")
  };
  // delivery

  const calDelBuyAndSell = useCallback((quantity, buyPrice, sellPrice) => {
    try {
      if (!quantity || isNaN(quantity)) {
        // If quantity is not provided or is NaN, set all values to 0
        setDelData({
          turnOver: 0,
          brokerageCharges: 0,
          STT: 0,
          transactionCharge: 0,
          IPFT: 0,
          SEBI: 0,
          stampCharge: 0,
          clearingCharge: 0,
          GST_Charges: 0,
          Grand_Total: 0,
        });
        return; // Exit the function
      }

      // Perform calculations only if quantity is valid
      let buyInit = quantity * buyPrice;
      let sellInit = quantity * sellPrice;
      let turnOver = buyInit + sellInit;
      let brokerageCharges = 0;
      let STT_Charges = buyInit * (1 / 1000) + sellInit * (1 / 1000);
      let Transaction_Charges =
        buyInit * (325 / 10000000) + sellInit * (325 / 10000000);
      let IPFT_Charges =
        (buyInit < 1000 ? 0 : buyInit * (10 / 10000000)) +
        (sellInit < 1000 ? 0 : sellInit * (10 / 10000000));
      let SEBI_Charges =
        (buyInit < 1000 ? 0 : buyInit * (10 / 10000000)) +
        (sellInit < 1000 ? 0 : sellInit * (10 / 10000000));
      let StampCharges = buyInit * (15 / 100000) + 0;
      let clearingCharges = buyInit * (2 / 100000) + sellInit * (2 / 100000);
      let GST_Charges =
        (brokerageCharges +
          Transaction_Charges +
          SEBI_Charges +
          clearingCharges) *
        (18 / 100);
      let Grand_Total =
        turnOver +
        brokerageCharges +
        STT_Charges +
        Transaction_Charges +
        IPFT_Charges +
        SEBI_Charges +
        StampCharges +
        clearingCharges +
        GST_Charges;
      console.log(
        `TurnOver: ${turnOver} \n BrokerageCharges: ${brokerageCharges} \n STT: ${STT_Charges} \n TransactionCharges: ${Transaction_Charges} \n IPFT: ${IPFT_Charges} \n SEBI: ${SEBI_Charges} \n StampCharge: ${StampCharges} \n ClearingCharge: ${clearingCharges} \n GST_Charges: ${GST_Charges} \n Grand_Total: ${Grand_Total}`
      );
      setDelData({
        turnOver: turnOver,
        brokerageCharges: brokerageCharges,
        STT: STT_Charges,
        transactionCharge: Transaction_Charges,
        IPFT: IPFT_Charges,
        SEBI: SEBI_Charges,
        stampCharge: StampCharges,
        clearingCharge: clearingCharges,
        GST_Charges: GST_Charges,
        Grand_Total: Grand_Total,
      });
    } catch (error) {
      console.error(
        "Error occurred while calculating delivery charges:",
        error
      );
      // Handle the error if needed
    }
  }, []);

  //Intraday

  const calIntBuyAndSell = useCallback((quantity, buyPrice, sellPrice) => {
    // EqIntraDay =======START========

    try {
      if (!quantity || isNaN(quantity)) {
        // If quantity is not provided or is NaN, set all values to 0
        setIntData({
          turnOver: 0,
          brokerageCharges: 0,
          STT: 0,
          transactionCharge: 0,
          IPFT: 0,
          SEBI: 0,
          stampCharge: 0,
          clearingCharge: 0,
          GST_Charges: 0,
          Grand_Total: 0,
        });
        return; // Exit the function
      }

      // Perform calculations only if quantity is valid

      let buyInit = quantity * buyPrice;
      let sellInit = quantity * sellPrice;
      let bCharges = 0;
      let turnOver = buyInit + sellInit;
      let brokerageCharges =
        (!buyInit ? 0 : bCharges + 10) + (!sellInit ? 0 : bCharges + 10);
      let STT_Charges = 0 + sellInit * (25 / 100000);
      let Transaction_Charges =
        buyInit * (325 / 10000000) + sellInit * (325 / 10000000);
      let IPFT_Charges =
        (buyInit < 1000 ? 0 : buyInit * (10 / 10000000)) +
        (sellInit < 1000 ? 0 : sellInit * (10 / 10000000));
      let SEBI_Charges =
        (buyInit < 1000 ? 0 : buyInit * (10 / 10000000)) +
        (sellInit < 1000 ? 0 : sellInit * (10 / 10000000));
      let StampCharges = buyInit * (3 / 100000) + 0;
      let clearingCharges = buyInit * (2 / 100000) + sellInit * (2 / 100000);
      let GST_Charges =
        (brokerageCharges +
          Transaction_Charges +
          SEBI_Charges +
          clearingCharges) *
        (18 / 100);
      let Grand_Total =
        turnOver +
        brokerageCharges +
        STT_Charges +
        Transaction_Charges +
        IPFT_Charges +
        SEBI_Charges +
        StampCharges +
        clearingCharges +
        GST_Charges;
      console.log(
        `TurnOver: ${turnOver} \n BrokerageCharges: ${brokerageCharges} \n STT: ${STT_Charges} \n TransactionCharges: ${Transaction_Charges} \n IPFT: ${IPFT_Charges} \n SEBI: ${SEBI_Charges} \n StampCharge: ${StampCharges} \n ClearingCharge: ${clearingCharges} \n GST_Charges: ${GST_Charges} \n Grand_Total: ${Grand_Total}`
      );
      setIntData({
        turnOver: turnOver,
        brokerageCharges: brokerageCharges,
        STT: STT_Charges,
        transactionCharge: Transaction_Charges,
        IPFT: IPFT_Charges,
        SEBI: SEBI_Charges,
        stampCharge: StampCharges,
        clearingCharge: clearingCharges,
        GST_Charges: GST_Charges,
        Grand_Total: Grand_Total,
      });
    } catch (error) {
      console.log(error);
    }

    // EqIntraDay =======END========
  }, []);

  const calFutBuyAndSell = useCallback((quantity, buyPrice, sellPrice) => {
    try {
      if (!quantity || isNaN(quantity)) {
        // If quantity is not provided or is NaN, set all values to 0
        setFutData({
          turnOver: 0,
          brokerageCharges: 0,
          STT: 0,
          transactionCharge: 0,
          IPFT: 0,
          SEBI: 0,
          stampCharge: 0,
          clearingCharge: 0,
          GST_Charges: 0,
          Grand_Total: 0,
        });
        return; // Exit the function
      }

      // Perform calculations only if quantity is valid
      let buyInit = quantity * buyPrice;
      let sellInit = quantity * sellPrice;
      let bCharges = 0;
      let turnOver = buyInit + sellInit;
      let brokerageCharges =
        (!buyInit ? 0 : bCharges + 10) + (!sellInit ? 0 : bCharges + 10);
      let STT_Charges = 0 + sellInit * (125 / 1000000);
      let Transaction_Charges =
        buyInit * (19 / 1000000) + sellInit * (19 / 1000000);
      let IPFT_Charges =
        (buyInit < 1000 ? 0 : buyInit * (10 / 10000000)) +
        (sellInit < 1000 ? 0 : sellInit * (10 / 10000000));
      let SEBI_Charges =
        (buyInit < 1000 ? 0 : buyInit * (10 / 10000000)) +
        (sellInit < 1000 ? 0 : sellInit * (10 / 10000000));
      let StampCharges = buyInit * (2 / 100000) + 0;
      let clearingCharges = buyInit * (3 / 100000) + sellInit * (3 / 100000);
      let GST_Charges =
        (brokerageCharges +
          Transaction_Charges +
          SEBI_Charges +
          clearingCharges) *
        (18 / 100);
      let Grand_Total =
        turnOver +
        brokerageCharges +
        STT_Charges +
        Transaction_Charges +
        IPFT_Charges +
        SEBI_Charges +
        StampCharges +
        clearingCharges +
        GST_Charges;
      console.log(
        `TurnOver: ${turnOver} \n BrokerageCharges: ${brokerageCharges} \n STT: ${STT_Charges} \n TransactionCharges: ${Transaction_Charges} \n IPFT: ${IPFT_Charges} \n SEBI: ${SEBI_Charges} \n StampCharge: ${StampCharges} \n ClearingCharge: ${clearingCharges} \n GST_Charges: ${GST_Charges} \n Grand_Total: ${Grand_Total}`
      );
      setFutData({
        turnOver: turnOver,
        brokerageCharges: brokerageCharges,
        STT: STT_Charges,
        transactionCharge: Transaction_Charges,
        IPFT: IPFT_Charges,
        SEBI: SEBI_Charges,
        stampCharge: StampCharges,
        clearingCharge: clearingCharges,
        GST_Charges: GST_Charges,
        Grand_Total: Grand_Total,
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  //Option

  const calOptBuyAndSell = useCallback((quantity, buyPrice, sellPrice) => {
    try {
      if (!quantity || isNaN(quantity)) {
        // If quantity is not provided or is NaN, set all values to 0
        setOptData({
          turnOver: 0,
          brokerageCharges: 0,
          STT: 0,
          transactionCharge: 0,
          IPFT: 0,
          SEBI: 0,
          stampCharge: 0,
          clearingCharge: 0,
          GST_Charges: 0,
          Grand_Total: 0,
        });
        return; // Exit the function
      }

      // Perform calculations only if quantity is valid

      let buyInit = quantity * buyPrice;
      let sellInit = quantity * sellPrice;
      let bCharges = 0;
      let turnOver = buyInit + sellInit;
      let brokerageCharges =
        (!buyInit ? 0 : bCharges + 10) + (!sellInit ? 0 : bCharges + 10);
      let STT_Charges = 0 + sellInit * (625 / 1000000);
      let Transaction_Charges = buyInit * (5 / 10000) + sellInit * (5 / 10000);
      let IPFT_Charges =
        (buyInit < 1000 ? 0 : buyInit * (50 / 10000000)) +
        (sellInit < 1000 ? 0 : sellInit * (50 / 10000000));
      let SEBI_Charges =
        (buyInit < 1000 ? 0 : buyInit * (10 / 10000000)) +
        (sellInit < 1000 ? 0 : sellInit * (10 / 10000000));
      let StampCharges = buyInit * (3 / 100000) + 0;
      let clearingCharges = buyInit * (10 / 10000) + sellInit * (10 / 10000);
      let GST_Charges =
        (brokerageCharges +
          Transaction_Charges +
          SEBI_Charges +
          clearingCharges) *
        (18 / 100);
      let Grand_Total =
        turnOver +
        brokerageCharges +
        STT_Charges +
        Transaction_Charges +
        IPFT_Charges +
        SEBI_Charges +
        StampCharges +
        clearingCharges +
        GST_Charges;
      console.log(
        `TurnOver: ${turnOver} \n BrokerageCharges: ${brokerageCharges} \n STT: ${STT_Charges} \n TransactionCharges: ${Transaction_Charges} \n IPFT: ${IPFT_Charges} \n SEBI: ${SEBI_Charges} \n StampCharge: ${StampCharges} \n ClearingCharge: ${clearingCharges} \n GST_Charges: ${GST_Charges} \n Grand_Total: ${Grand_Total}`
      );
      setOptData({
        turnOver: turnOver,
        brokerageCharges: brokerageCharges,
        STT: STT_Charges,
        transactionCharge: Transaction_Charges,
        IPFT: IPFT_Charges,
        SEBI: SEBI_Charges,
        stampCharge: StampCharges,
        clearingCharge: clearingCharges,
        GST_Charges: GST_Charges,
        Grand_Total: Grand_Total,
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    calDelBuyAndSell(
      inputValue.quantity,
      inputValue.buyPrice,
      inputValue.sellPrice
    );
  }, [
    calDelBuyAndSell,
    inputValue.quantity,
    inputValue.buyPrice,
    inputValue.sellPrice,
  ]);

  useEffect(() => {
    calIntBuyAndSell(
      input2Value.quantity,
      input2Value.buyPrice,
      input2Value.sellPrice
    );
  }, [
    calIntBuyAndSell,
    input2Value.quantity,
    input2Value.buyPrice,
    input2Value.sellPrice,
  ]);

  useEffect(() => {
    calFutBuyAndSell(
      input3Value.quantity,
      input3Value.buyPrice,
      input3Value.sellPrice
    );
  }, [
    calFutBuyAndSell,
    input3Value.quantity,
    input3Value.buyPrice,
    input3Value.sellPrice,
  ]);

  useEffect(() => {
    calOptBuyAndSell(
      input4Value.quantity,
      input4Value.buyPrice,
      input4Value.sellPrice
    );
  }, [
    calOptBuyAndSell,
    input4Value.quantity,
    input4Value.buyPrice,
    input4Value.sellPrice,
  ]);
  
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>FINOVO’S ONLINE CALCULATOR | IT’S BEYOND THE SIMPLE MATH</title>
        <meta
          name="description"
          content="Make your online calculator is more sophisticated & simplified with Finovo’s latest ‘ONLINE CALCULATOR’ solution.
"
        />
      </Helmet>
      <h1 className="hidden">Online Calculator</h1>
      <h2 className="hidden">
        Online calculators now go beyond simple maths. They involve consumers in
        numerous situations and provide insightful data. The interactive
        calculator from finivio.tech helps organizations generate leads by
        offering tailored solutions and in-depth user insights. To see how it
        can turn your idea become a reality, schedule a demo. extreme heights.
      </h2>
      <div className="sm:px-10 sm:py-5 md:px-20 md:py-10 relative mt-16">
        <div>
          <img className="absolute top-0 right-0 w-[300px] max-[596px]:w-[200px] max-[319px]:w-[130px]" src={Shadow} />
          <p className="font-bold text-4xl my-10">Online Calculator</p>
          <p>
            Online calculators now go beyond simple maths. They involve
            consumers in numerous situations and provide insightful data. The
            interactive calculator from finivio.tech helps organizations
            generate leads by offering tailored solutions and in-depth user
            insights. To see how it can turn your idea become a reality,
            schedule a demo. extreme heights.
          </p>
          {/* == */}
          <div className="bg-lightGrey max-w-full rounded-2xl border-2 pb-10 my-6">
        <div className="mt-8 flex justify-around flex-wrap">
          <div>
            <h5 className="text-white py-4">Equity Delivery</h5>
            <div className="border-white border sm:w-[250px] md:w-[398px] flex gap-12">
              <div className="">
                <label className="text-white">BUY</label>

                <input
                  onWheel={handleWheel}
                  type="number"
                  title="Buy Price"
                  name="buyPrice"
                  value={inputValue.buyPrice}
                  onChange={handleChange}
                  className="sm:w-[50px] md:w-[100px] no-spinner"
                />
              </div>
              <div className="">
                <label className="text-white">SELL</label>
                <input
                  onWheel={handleWheel}
                  type="number"
                  title="Sell Price"
                  name="sellPrice"
                  value={inputValue.sellPrice}
                  onChange={handleChange}
                  className="sm:w-[50px] md:w-[100px] no-spinner"
                />
              </div>
              <div className="">
                <label className="text-white md:hidden sm:block">QTY.</label>
                <label className="text-white sm:hidden md:block">QUANTITY</label>
                <input
                  onWheel={handleWheel}
                  type="number"
                  name="quantity"
                  onChange={handleChange}
                  value={inputValue.quantity}
                  className="sm:w-[50px] md:w-[100px] no-spinner"
                />
              </div>
            </div>
            {/* Result show here */}
            <div className="pt-6 border-white border bg-grey">
              <div className="flex justify-between px-2">
                <p className="text-white">TurnOver</p>
                <p className="text-white">{delData.turnOver.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">Brokerage</p>
                <p className="text-white">
                  {delData.brokerageCharges.toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">STT Charges</p>
                <p className="text-white">{delData.STT.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">Transaction</p>
                <p className="text-white">
                  {delData.transactionCharge.toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">GST</p>
                <p className="text-white">{delData.GST_Charges.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">IPFT Charges</p>
                <p className="text-white">{delData.IPFT.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">SEBI Charges</p>
                <p className="text-white">{delData.SEBI.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">Stamp Duty</p>
                <p className="text-white">{delData.stampCharge.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">clearing Charges</p>
                <p className="text-white">
                  {delData.clearingCharge.toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">GrandTotal</p>
                <p className="text-white">{delData.Grand_Total.toFixed(2)}</p>
              </div>
            </div>
            <div><p className="bg-white text-red">{messageDel}</p></div>
            <div className="flex justify-between">
              {/* <div>
                <button onClick={calDelBuyAndSell} className="bg-yellow">
                  Calculate
                </button>
              </div> */}
              <div>
                <button onClick={clearDelState} className="bg-green p-2 rounded-xl mt-1">
                  RESET
                </button>
              </div>
            </div>
          </div>
          <div>
            <h5 className="text-white py-4 ">Equity Intraday</h5>
            <div className="border-white border sm:w-[250px] md:w-[398px] flex gap-12">
              <div className="">
                <label className="text-white">BUY</label>
                <input
                  onWheel={handleWheel}
                  type="number"
                  title="Buy Price"
                  name="buyPrice"
                  value={input2Value.buyPrice}
                  onChange={handleChangeI}
                  className="sm:w-[50px] md:w-[100px] no-spinner"
                />
              </div>
              <div className="">
                <label className="text-white">SELL</label>
                <input
                  onWheel={handleWheel}
                  type="number"
                  title="Sell Price"
                  name="sellPrice"
                  value={input2Value.sellPrice}
                  onChange={handleChangeI}
                  className="sm:w-[50px] md:w-[100px] no-spinner"
                />
              </div>
              <div className="">
              <label className="text-white md:hidden sm:block">QTY.</label>
                <label className="text-white sm:hidden md:block">QUANTITY</label>
                <input
                  onWheel={handleWheel}
                  type="number"
                  title="Quantity"
                  name="quantity"
                  value={input2Value.quantity}
                  onChange={handleChangeI}
                  className="sm:w-[50px] md:w-[100px] no-spinner"
                />
              </div>
            </div>
            <div className="pt-6 border-white border">
              <div className="flex justify-between px-2">
                <p className="text-white">TurnOver</p>
                <p className="text-white">{intData.turnOver.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">Brokerage</p>
                <p className="text-white">
                  {intData.brokerageCharges.toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">STT Charges</p>
                <p className="text-white">{intData.STT.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">Transaction</p>
                <p className="text-white">
                  {intData.transactionCharge.toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">GST</p>
                <p className="text-white">{intData.GST_Charges.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">IPFT Charges</p>
                <p className="text-white">{intData.IPFT.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">SEBI Charges</p>
                <p className="text-white">{intData.SEBI.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">Stamp Duty</p>
                <p className="text-white">{intData.stampCharge.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">clearing Charges</p>
                <p className="text-white">
                  {intData.clearingCharge.toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">GrandTotal</p>
                <p className="text-white">{intData.Grand_Total.toFixed(2)}</p>
              </div>
            </div>
            <div><p className="bg-white text-red">{messageInt}</p></div>
            <div className="flex justify-between">
              {/* <div>
                <button onClick={calIntBuyAndSell} className="bg-yellow">
                  Calculate
                </button>
              </div> */}
              <div>
                <button onClick={clearIntState} className="bg-green mt-1 p-2 rounded-xl">
                  RESET
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className=" mt-8 flex justify-around flex-wrap">
          <div>
            <h5 className="text-white py-4">Equity Future</h5>
            <div className="border-white border sm:w-[250px] md:w-[398px] flex gap-12">
              <div className="">
                <label className="text-white">BUY</label>
                <input
                  onWheel={handleWheel}
                  type="number"
                  title="Buy Price"
                  name="buyPrice"
                  value={input3Value.buyPrice}
                  onChange={handleChangeF}
                  className="sm:w-[50px] md:w-[100px] no-spinner"
                />
              </div>
              <div className="">
                <label className="text-white">SELL</label>
                <input
                  onWheel={handleWheel}
                  type="number"
                  title="Sell Price"
                  name="sellPrice"
                  value={input3Value.sellPrice}
                  onChange={handleChangeF}
                  className="sm:w-[50px] md:w-[100px] no-spinner"
                />
              </div>
              <div className="">
              <label className="text-white md:hidden sm:block">QTY.</label>
                <label className="text-white sm:hidden md:block">QUANTITY</label>
                <input
                  onWheel={handleWheel}
                  type="number"
                  name="quantity"
                  title="Quantity"
                  value={input3Value.quantity}
                  onChange={handleChangeF}
                  className="sm:w-[50px] md:w-[100px] no-spinner"
                />
              </div>
            </div>
            {/* Result show here */}
            <div className="pt-6 border-white border">
              <div className="flex justify-between px-2">
                <p className="text-white">TurnOver</p>
                <p className="text-white">{futData.turnOver.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">Brokerage</p>
                <p className="text-white">
                  {futData.brokerageCharges.toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">STT Charges</p>
                <p className="text-white">{futData.STT.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">Transaction</p>
                <p className="text-white">
                  {futData.transactionCharge.toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">GST</p>
                <p className="text-white">{futData.GST_Charges.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">IPFT Charges</p>
                <p className="text-white">{futData.IPFT.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">SEBI Charges</p>
                <p className="text-white">{futData.SEBI.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">Stamp Duty</p>
                <p className="text-white">{futData.stampCharge.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">clearing Charges</p>
                <p className="text-white">
                  {futData.clearingCharge.toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">GrandTotal</p>
                <p className="text-white">{futData.Grand_Total.toFixed(2)}</p>
              </div>
            </div>
            <div><p className="bg-white text-red">{messageFut}</p></div>
            <div className="flex justify-between">
              {/* <div>
                <button onClick={calFutBuyAndSell} className="bg-yellow">
                  Calculate
                </button>
              </div> */}
              <div>
                <button onClick={clearFutState} className="bg-green rounded-xl p-2 mt-1">
                  RESET
                </button>
              </div>
            </div>
          </div>
          <div>
            <h5 className="text-white py-4 ">Equity Options</h5>
            <div className="border-white border sm:w-[250px] md:w-[398px] flex gap-12">
              <div className="">
                <label className="text-white">BUY</label>
                <input
                  onWheel={handleWheel}
                  type="number"
                  name="buyPrice"
                  title="Buy Price"
                  value={input4Value.buyPrice}
                  onChange={handleChangeO}
                  className="sm:w-[50px] md:w-[100px] no-spinner"
                />
              </div>
              <div className="">
                <label className="text-white">SELL</label>
                <input
                  onWheel={handleWheel}
                  type="number"
                  name="sellPrice"
                  title="Sell Price"
                  value={input4Value.sellPrice}
                  onChange={handleChangeO}
                  className="sm:w-[50px] md:w-[100px] no-spinner"
                />
              </div>
              <div className="">
              <label className="text-white md:hidden sm:block">QTY.</label>
                <label className="text-white sm:hidden md:block">QUANTITY</label>
                <input
                  onWheel={handleWheel}
                  type="number"
                  name="quantity"
                  title="Quantity"
                  value={input4Value.quantity}
                  onChange={handleChangeO}
                  className="sm:w-[50px] md:w-[100px] no-spinner"
                />
              </div>
            </div>
            <div className="pt-6 border-white border">
              <div className="flex justify-between px-2">
                <p className="text-white">TurnOver</p>
                <p className="text-white">{optData.turnOver.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">Brokerage</p>
                <p className="text-white">
                  {optData.brokerageCharges.toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">STT Charges</p>
                <p className="text-white">{optData.STT.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">Transaction</p>
                <p className="text-white">
                  {optData.transactionCharge.toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">GST</p>
                <p className="text-white">{optData.GST_Charges.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">IPFT Charges</p>
                <p className="text-white">{optData.IPFT.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">SEBI Charges</p>
                <p className="text-white">{optData.SEBI.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">Stamp Duty</p>
                <p className="text-white">{optData.stampCharge.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">clearing Charges</p>
                <p className="text-white">
                  {optData.clearingCharge.toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between px-2">
                <p className="text-white">GrandTotal</p>
                <p className="text-white">{optData.Grand_Total.toFixed(2)}</p>
              </div>
            </div>
            <div><p className="bg-white text-red">{messageOpt}</p></div>
            <div className="flex justify-between">
              {/* <div>
                <button onClick={calOptBuyAndSell} className="bg-yellow">
                  Calculate
                </button>
              </div> */}
              <div>
                <button onClick={clearOptState} className="bg-green p-2 mt-1 rounded-xl">
                  RESET
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
          {/* == */}
          <div className="my-10">
            <Carousel
              responsive={CalcResponsive}
              autoPlay={true}
              autoPlaySpeed={2000}
              infinite={true}
            >
              <div className="">
                <RImg
                  externalClassName="flex justify-center"
                  innerImgClassName="md:w-[70%] md:h-[60vh]"
                  src={Margin}
                />
              </div>
              <div className="">
                <RImg
                  externalClassName="flex justify-center"
                  innerImgClassName="md:w-[70%] md:h-[60vh] "
                  src={Equity}
                />
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};
