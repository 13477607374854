import React from "react";
import { ImFacebook2 } from "react-icons/im";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { BsFillThreadsFill } from "react-icons/bs";
import { FaInstagramSquare } from "react-icons/fa";
import "./styles.scss"
function SocialLink() {
  return (
    <>
      <div className="relative sm:hidden md:block">
        <div className="strip w-[60px] fixed right-0 top-56 rounded-tl-xl rounded-bl-xl">
          <div className="my-4 flex justify-center">
            <div>
            <p className="mb-2">
              {" "}
              <a href="https://www.facebook.com/profile.php?id=61555182434059"><ImFacebook2 style={{ width: "50px", height: "30px" }} /></a>
            </p>
            <p className="mb-2">
              {" "}
              <a href="https://www.linkedin.com/company/finovo-tech"><FaLinkedin style={{ width: "50px", height: "34px" }} /></a>
            </p>
            <p className="mb-2">
              {" "}
              <a href="https://www.threads.net/@finovotech"><BsFillThreadsFill style={{ width: "50px", height: "32px" }} /></a>
            </p>
            <p className="mb-2">
              {" "}
              <a href="https://twitter.com/Finovotech"><FaSquareXTwitter style={{ width: "50px", height: "32px" }} /></a>
            </p>
            <p className="">
              {" "}
              <a href="https://www.instagram.com/finovotech/"><FaInstagramSquare style={{ width: "50px", height: "32px" }} /></a>
            </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SocialLink;
