import React from "react";
import VectorImg from "../../assest/product/vector.png";
import "./styles.scss";

const ProductService = () => {
  return (
    <>
      <div className="product-container flex justify-between sm:py-10 md:py-20">
        <div className="vector-img w-[30%]">
          <img src={VectorImg} alt="" className="" />
        </div>
        <div>
          <p className="product-text text-white text-4xl text-end sm:p-6 md:p-16 max-[686px]:text-3xl">
            Experience the best <br /> with our top-notch
            <br />
            <span className="text-lightBlue font-bold text-6xl max-[480px]:text-3xl max-[686px]:text-5xl">Product </span>
            <span className="text-6xl font-bold max-[480px]:text-3xl max-[686px]:text-5xl">& </span>
            <span className="text-tertiary font-bold text-6xl max-[480px]:text-3xl max-[686px]:text-5xl">Services</span>
            <p className="text-xl mt-9 max-[480px]:text-sm max-[686px]:text-lg">
              We are passionate about taking on new challenges and <br />
              strive to solve complex problems using automation and the<br/> latest
              technology.
            </p>
          </p>
        </div>
      </div>
    </>
  );
};

export default ProductService;
