import React, { useState, useEffect, useRef } from "react";
import Carousel from "react-multi-carousel";
import { RImg } from "../rImg";
import Email from "../../assest/product/emailFinovo.png";
import EmailOtp from "../../assest/product/emailotpFinovo.png";
import DigiLocker from "../../assest/product/digilockerFinovo.png";
import Verification from "../../assest/product/aadharPanFinovo.png";
import Bank from "../../assest/product/bankDetails.png";
import Segment from "../../assest/product/segmentImage.png";
import Photo from "../../assest/product/uploadPhoto.png";
import "react-multi-carousel/lib/styles.css";
import FooterLogo from "../../assest/about/footerLogo.png";
import { useNavigate } from "react-router-dom";
import { ROUTES_NAME } from "../../routers/routeConstants";
import { Helmet } from "react-helmet";
import Shadow from "../../assest/about/logoShadow.png";
import One from "../../assest/ekyc/Group 254.png";
import Two from "../../assest/ekyc/Group 255.png";
import Three from "../../assest/ekyc/Group 256.png";
import Four from "../../assest/ekyc/Group 257.png";
import Five from "../../assest/ekyc/Group 258.png";
import OneW from "../../assest/ekyc/1web.png";
import TwoW from "../../assest/ekyc/2web.png";
import ThreeW from "../../assest/ekyc/3web.png";
import FourW from "../../assest/ekyc/4web.png";
import FiveW from "../../assest/ekyc/5Web.png";
import Aadhar from "../../assest/ekyc/aadhar.png";
import Face from "../../assest/ekyc/face verify.png";
import Pan from "../../assest/ekyc/pancard.png";
import Finger from "../../assest/ekyc/fingerprint.png";
import Sign from "../../assest/ekyc/digitalsign.png";
import ArrowUp from "../../assest/ekyc/up_arrow.png";
import ArrowDown from "../../assest/ekyc/down_arrow.png";
import { Footer } from "../../layout/footer";

// const mapper = [
//   {
//     id: 1,
//     ques: "What is Finovo's rapid E-KYC and how does it work?",
//     ans: "Finovo's E-KYC is an electronic verification process that enables quick and efficient customer onboarding. It validates identity documents through automation and streamlines the KYC process, reducing manual intervention and turnaround time.",
//     arrowUp: ArrowUp,
//     arrowDown: ArrowDown,
//   },
//   {
//     id: 2,
//     ques: "Is Finovo's E-KYC compliant with regulatory requirements?",
//     ans: "Yes, Finovo's E-KYC solution is compliant with all relevant regulations and guidelines. It ensures data security, privacy protection, and adherence to anti-money laundering laws, making it a trustworthy option for businesses seeking KYC solutions.",
//     arrowUp: ArrowUp,
//     arrowDown: ArrowDown,
//   },
//   {
//     id: 3,
//     ques: "How can Finovo's E-KYC benefit businesses in different industries?",
//     ans: "Finovo's E-KYC offers enhanced customer experience, reduces onboarding costs, improves operational efficiency, and minimizes fraud risks. It can be seamlessly integrated into various industries, including banking, insurance, fintech, and e-commerce, to accelerate growth and compliance.",
//     arrowUp: ArrowUp,
//     arrowDown: ArrowDown,
//   },
//   {
//     id: 4,
//     ques: "What are the key features of Finovo's rapid E-KYC solution?",
//     ans: "Finovo's E-KYC solution includes document verification, facial recognition, biometric authentication, OCR technology, and live video verification. These features ensure robust security, accurate verification, and a seamless customer experience, making it a preferred choice for businesses.",
//     arrowUp: ArrowUp,
//     arrowDown: ArrowDown,
//   },
//   {
//     id: 5,
//     ques: "How does Finovo's E-KYC solution compare to traditional KYC methods?",
//     ans: "Finovo's E-KYC solution offers real-time verification, instant results, automation, and a user-friendly interface, making it faster, more accurate, and cost-effective compared to traditional manual KYC methods. It eliminates paperwork, human errors, and delays, empowering businesses to onboard customers swiftly and securely.",
//     arrowUp: ArrowUp,
//     arrowDown: ArrowDown,
//   },
// ];

export const Ekyc = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const crossRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleLinkClick = (linkTag) => {
    scrollToTop();
    handleClose();
    navigate(linkTag);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleRedirect = (pageUrl) => {
    window.open(pageUrl);
  };
  const handleOutsideClick = (e) => {
    if (
      menuRef.current &&
      crossRef.current &&
      !menuRef.current.contains(e.target) &&
      !crossRef.current.contains(e.target)
    ) {
      handleClose();
    }
  };
  const handleScroll = () => {
    const scrollThreshold = 700;
    if (window.scrollY > scrollThreshold) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);

      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleClick = () => {
    handleLinkClick(ROUTES_NAME.HOME);
  };
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>FINOVO’S RAPID E-KYC |SIMPLIFY THE IDENTITIES</title>
        <meta
          name="description"
          content="Streamline Your Identity Verification Process with Finovo’s rapid E-KYC solutions.
"
        />
      </Helmet>
      <h1 className="hidden">Rapid e-KYC Solutions for India</h1>
      <h2 className="hidden">
        E-KYC (electronic Know Your Customer) is a digital process that lets
        businesses verify customer identities remotely. It involves collecting
        digital info, documents, and sometimes biometrics for verification. This
        speeds up onboarding, cuts paperwork, and enhances security while
        complying with regulations. Simplify paperless E-KYC with Aadhaar and
        Digi-Locker to simplify access to financial services. By automating
        account opening, our effective program lessens staff workload while
        facilitating easy trading and Demat account setup.
      </h2>
      <div className="sm:px-10 sm:py-5 md:px-20 md:py-10 relative mt-16">
        <img
          className="absolute top-0 right-0 w-[300px] max-[596px]:w-[200px] max-[319px]:w-[130px]"
          src={Shadow}
        />
        <p className="font-bold text-4xl mb-6">E-KYC</p>
        <p className="md:text-xl">
          E-KYC (electronic Know Your Customer) is a digital process that lets
          businesses verify customer identities remotely. It involves collecting
          digital info, documents, and sometimes biometrics for verification.
          This speeds up onboarding, cuts paperwork, and enhances security while
          complying with regulations. Simplify paperless E-KYC with Aadhaar and
          Digi-Locker to simplify access to financial services. By automating
          account opening, our effective program lessens staff workload while
          facilitating easy trading and Demat account setup.
        </p>
      </div>

      <Carousel
        className="pt-4"
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={2000}
        infinite={true}
      >
        <div className="">
          <RImg
            externalClassName="flex justify-center"
            innerImgClassName="md:w-[80%] sm:mt-10 xl:mt-0"
            src={Email}
          />
        </div>
        <div className="">
          <RImg
            innerImgClassName="md:w-[80%] sm:mt-10 xl:mt-0"
            externalClassName="flex justify-center"
            src={EmailOtp}
          />
        </div>
        <div className="">
          <RImg
            innerImgClassName="md:w-[80%] sm:mt-10 xl:mt-0"
            externalClassName="flex justify-center"
            src={DigiLocker}
          />
        </div>
        <div className="">
          <RImg
            innerImgClassName="md:w-[80%] sm:mt-10 xl:mt-0"
            externalClassName="flex justify-center"
            src={Verification}
          />
        </div>
        <div className="">
          <RImg
            innerImgClassName="md:w-[80%] sm:mt-10 xl:mt-0"
            externalClassName="flex justify-center"
            src={Bank}
          />
        </div>
        <div className="">
          <RImg
            innerImgClassName="md:w-[80%] md:h-[65vh]"
            externalClassName="flex justify-center"
            src={Segment}
          />
        </div>
        <div className="">
          <RImg
            innerImgClassName="md:w-[80%] md:h-[65vh]"
            externalClassName="flex justify-center"
            src={Photo}
          />
        </div>
      </Carousel>
      <div className="md:flex md:justify-between sm:px-10  md:px-20 ">
        <div className="md:w-[50%] font-bold text-2xl sm:hidden md:block">
          <p>What does</p>
          <p>finovo offer in</p>
          <p>rapid E-KYC ?</p>
        </div>
        <div className="font-bold text-2xl mb-6 md:hidden">
          <p>What does finovo offer in rapid E-KYC ?</p>
        </div>
        <div className="md:w-[50%]">
          <ul className="md:text-xl list-disc">
            <li>Verification of documents in real-time</li>
            <li>Quick Compliance Inspections</li>
            <li>An intuitive interface</li>
            <li>Flexible Workflows</li>
            <li>Secure data storage</li>
            <li>API integration with Back office, DP & Exchanges</li>
            <li>1 click data push via API</li>
          </ul>
        </div>
      </div>
      <div className="sm:p-10 md:p-20 ">
        <p className="font-bold text-2xl mt-6">Process for Re-KYC</p>
        <div className="md:hidden">
          <img src={One} alt="aadhar-verification" />
          <img src={Two} alt="verification" />
          <img src={Three} alt="bank-details" />
          <img src={Four} alt="nominee-details" />
          <img src={Five} alt="electronic-sign" />
        </div>
        <div className="sm:hidden md:block">
          <div className="flex justify-between flex-wrap">
            <img src={OneW} alt="aadhar-verification" />
            <img src={TwoW} alt="verification" />
            <img src={ThreeW} alt="bank-details" />
            <img src={FourW} alt="nominee-details" />
            <img src={FiveW} alt="electronic-sign" />
          </div>
        </div>
      </div>
      <div className="sm:px-10 md:px-20">
        <p className="text-2xl font-bold my-6">
          Our all-in-one electronic KYC procedure wil streamline your onboarding
          and make the process go more smoothly.
        </p>
        <p className="sm:mb-4 md:mb-0 md:text-xl">
          Our quick E-KYC services at Finovo Tech ture laborious maual
          activities into effective online solutions, streamlining your
          workflow. Reduce the amount of paper you need, save time, and increase
          energy.
        </p>
        <dl className="md:text-xl list-disc">
          <li className="sm:mb-2 md:mb-0">
            Vanish physical documents-it makes use of cloud-based Digilockeer to
            quickly obtain necessary papers for user validation.
          </li>
          <li>
            Budget-friendly-State-of-the-art technology that welcomes the future
            of effective procedures while simultaneously lowering the costs of
            human consumer veriication.
          </li>
        </dl>
      </div>
      <div className="sm:px-10 md:px-20">
        <p className="text-2xl font-bold my-6">
          Quick and accurate onboarding with identity verification enabled by
          AI.
        </p>
        <p className="md:text-xl">
          Using cutting-edge technology, such as machine learning and real-time
          data analysis, our AI-driven KYC and identity verification solution
          quickly authenticates customer identities. To ensure effective
          onboarding, 80% cost savings, and increased customer satisfaction, our
          face-match technology detects phony IDs and reduces false positives by
          90%.
        </p>
      </div>

      <div className="sm:px-10  md:px-20">
        <p className="font-bold text-2xl mt-10 mb-6">
          Documents of Identity That We Check
        </p>
        <div className="md:flex md:justify-between">
          <p className="md:text-xl">
            <span className="font-bold text-xl">The Aadhar E-KYC</span>
            <br />
            With the paperless XML, digital identity verification, and
            Aadhaar-compliant Finovo solutions, maximize onboarding. The
            procedure is streamlined for accuracy and efficiency using our
            advanced Aadhaar API, which guarantees compliance with regulatory
            agencies like RBI and SEBI.
          </p>
          <img
            src={Aadhar}
            alt="aadhar"
            className="bg-black sm:mt-3 sm:hidden md:block md:mt-0 md:ml-10 md:w-[300px]"
          />
        </div>
      </div>
      <img src={Aadhar} alt="aadhar" className="bg-black mt-3 md:hidden" />
      <div className="sm:px-10  md:px-20 mt-10">
        <div className="md:flex md:justify-between">
          <p className="md:text-xl">
            <span className="font-bold text-xl">Video KYC</span>
            <br />
            Video-based customer identification verification and self-onboarding
            are made easier by Finovo's liveness detection technology. By
            comparing faces to ID documents, our AI-powered liveness checks
            reduce the danger of identity theft and biometric counterfeiting
            while guaranteeing complete regulatory compliance.
          </p>
          <img
            src={Face}
            alt="aadhar"
            className="bg-black sm:mt-3 sm:hidden md:block md:mt-0 md:ml-10 md:w-[300px]"
          />
        </div>
      </div>
      <img src={Face} alt="aadhar" className="bg-black mt-3 md:hidden" />
      <div className="sm:px-10  md:px-20 mt-10">
        <div className="md:flex md:justify-between">
          <p className="md:text-xl">
            <span className="font-bold text-xl">
              Authentication of PAN Cards
            </span>
            <br />
            Businesses can easily authenticate PAN cards by cross-referencing
            important facts like name and date of birth with Finovo's
            India-centric APIs. For seamless and dependable identification, our
            system makes sure that format is precise, verifies PAN numbers, and
            does precise photo and image verification.
          </p>
          <img
            src={Pan}
            alt="aadhar"
            className="bg-black sm:mt-3 sm:hidden md:block md:mt-0 md:ml-10 md:w-[300px]"
          />
        </div>
      </div>
      <img src={Pan} alt="aadhar" className="bg-black mt-3 md:hidden" />
      <div className="sm:px-10  md:px-20 mt-10">
        <div className="md:flex md:justify-between">
          <p className="md:text-xl">
            <span className="font-bold text-xl">
              Authentication via Biometrics for Increased Security
            </span>
            <br />
            With fingerprint scanning and facial recognition built in, Finovo's
            biometric authentication solution improves security while providing
            a safe and convenient user experience. We reduce the possibility of
            identity theft by using biometric data, guaranteeing the highest
            level of protection for client information.
          </p>
          <img
            src={Finger}
            alt="aadhar"
            className="bg-black sm:mt-3 sm:hidden md:block md:mt-0 md:ml-10 md:w-[300px]"
          />
        </div>
      </div>
      <img src={Finger} alt="aadhar" className="bg-black mt-3 md:hidden" />
      <div className="sm:px-10  md:px-20 mt-10">
        <div className="md:flex md:justify-between">
          <p className="md:text-xl">
            <span className="font-bold text-xl">
              Effective Digital Signature for Documents.
            </span>
            <br />
            Use secure electronic document signing to streamline KYC. Save time,
            lessen your influence on the environment, and rely on Finovo to
            store sensitive data in a secure, legally binding manner
          </p>
          <img
            src={Sign}
            alt="aadhar"
            className="bg-black sm:mt-3 sm:hidden md:block md:mt-0 md:ml-10 md:w-[300px]"
          />
        </div>
      </div>
      <img src={Sign} alt="aadhar" className="bg-black mt-3 md:hidden" />
      <div className=" sm:px-10 md:px-20">
        <p className="mb-8 mt-4 text-xl font-bold">FAQ ?</p>
        <div className="flex justify-between mb-3">
          <p
            // onClick={() => setShow(true)}
            className="w-[80%] sm:text-sm md:text-base lg:text-lg font-bold"
          >
            1. What is Finovo's rapid E_KYC and how does it work ?
          </p>
          {show && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow(false)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowUp}
                alt="arrow-up"
              />
            </div>
          )}
          {!show && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow(true)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowDown}
                alt="arrow-down"
              />
            </div>
          )}
        </div>
        {show && (
          <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg">
            Finovo's E-KYC is an electronic verification process that enables
            quick and efficient customer onboarding. It validates identity
            documents through automation and streamlines the KYC process,
            reducing manual intervention and turnaround time.
          </p>
        )}
        <div className="flex justify-between mb-3">
          <p className="w-[80%] sm:text-sm md:text-base lg:text-lg font-bold">
            2. Is Finovo's E-KYC compliant with regulatory requirements ?
          </p>
          {show2 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow2(false)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowUp}
                alt="arrow-up"
              />
            </div>
          )}
          {!show2 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow2(true)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowDown}
                alt="arrow-down"
              />
            </div>
          )}
        </div>
        {show2 && (
          <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg ">
            Yes, Finovo's E-KYC solution is compliant with all relevant
            regulations and guidelines. It ensures data security, privacy
            protection, and adherence to anti-money laundering laws, making it a
            trustworthy option for businesses seeking KYC solutions.
          </p>
        )}
        <div className="flex justify-between mb-3 ">
          <p className="w-[80%] sm:text-sm md:text-base lg:text-lg font-bold">
            3. How can Finovo's E-KYC benefit businesses in different industries
            ?
          </p>
          {show3 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow3(false)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowUp}
                alt="arrow-up"
              />
            </div>
          )}
          {!show3 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow3(true)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowDown}
                alt="arrow-down"
              />
            </div>
          )}
        </div>
        {show3 && (
          <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg ">
            Finovo's E-KYC offers enhanced customer experience, reduces
            onboarding costs, improves operational efficiency, and minimizes
            fraud risks. It can be seamlessly integrated into various
            industries, including banking, insurance, fintech, and e-commerce,
            to accelerate growth and compliance.
          </p>
        )}
        <div className="flex justify-between mb-3">
          <p className="w-[80%] sm:text-sm md:text-base lg:text-lg font-bold">
            4. What are the key features of Finovo's rapid E-KYC solution ?
          </p>
          {show4 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow4(false)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowUp}
                alt="arrow-up"
              />
            </div>
          )}
          {!show4 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow4(true)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowDown}
                alt="arrow-down"
              />
            </div>
          )}
        </div>
        {show4 && (
          <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg">
            Finovo's E-KYC solution includes document verification, facial
            recognition, biometric authentication, OCR technology, and live
            video verification. These features ensure robust security, accurate
            verification, and a seamless customer experience, making it a
            preferred choice for businesses.
          </p>
        )}
        <div className="flex justify-between mb-3">
          <p className="w-[80%] sm:text-sm md:text-base lg:text-lg font-bold">
            5. How does Finovo's E-KYC solution compare to traditional KYC
            methods ?
          </p>
          {show5 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow5(false)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowUp}
                alt="arrow-up"
              />
            </div>
          )}
          {!show5 && (
            <div className="w-[20%] flex justify-end items-center">
              <img
                onClick={() => setShow5(true)}
                className="sm:w-[30px] md:w-[35px] lg:w-[40px]"
                src={ArrowDown}
                alt="arrow-down"
              />
            </div>
          )}
        </div>
        {show5 && (
          <p className="mb-8 mt-2 sm:text-sm md:text-base lg:text-lg">
            Finovo's E-KYC solution offers real-time verification, instant
            results, automation, and a user-friendly interface, making it
            faster, more accurate, and cost-effective compared to traditional
            manual KYC methods. It eliminates paperwork, human errors, and
            delays, empowering businesses to onboard customers swiftly and
            securely.
          </p>
        )}
      </div>
      {/* <Footer/> */}
    </>
  );
};
