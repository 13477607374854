import React,{ useState,useEffect, useRef } from "react";
import Shadow from "../../assest/about/logoShadow.png"
import { ROUTES_NAME } from "../../routers/routeConstants";
import FooterLogo from "../../assest/about/footerLogo.png"
import { useNavigate } from "react-router-dom"; 

function ContactUs() {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);
    const crossRef = useRef(null);
    const [scrolled, setScrolled] = useState(false);
    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        window.addEventListener("scroll", handleScroll);
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
    
          document.removeEventListener("mousedown", handleOutsideClick);
        };
      }, []);
    const navigate = useNavigate();
    const handleClose = () => {
        setIsOpen(false);
      };
    const handleLinkClick = (linkTag) => {
        scrollToTop();
        handleClose();
        navigate(linkTag);
      };
      const handleOutsideClick = (e) => {
        if (
          menuRef.current &&
          crossRef.current &&
          !menuRef.current.contains(e.target) &&
          !crossRef.current.contains(e.target)
        ) {
          handleClose();
        }
      };

      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };
      const handleScroll = () => {
        const scrollThreshold = 700;
        if (window.scrollY > scrollThreshold) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      };

    const handleClick = () => {
        handleLinkClick(ROUTES_NAME.HOME);
      };

  return (
    <>
      <div className="sm:p-8 md:p-10 lg:p-12 xl:p-16 relative mt-16">
      <img className="absolute top-0 right-0 w-[300px] max-[596px]:w-[200px] max-[319px]:w-[130px]" src={Shadow}/>
        <h1 className="sm:text-2xl lg:text-4xl font-bold mb-10">For Contact</h1>
        <h2 className="sm:text-lg lg:text-2xl text-center font-bold mb-2">"Need help ?" we are waiting for your touch</h2>
        <p className="sm:text-sm lg:text-base text-center mb-10" >
          We are available to assist you! Let us know what you're searching for,
          and we'll put you in touch with the appropriate individuals.
        </p>
      </div>
    <hr/>
      {/* footer */}
      {/* <div className="footer-color flex justify-between p-16 flex-wrap-reverse">
        <div
          onClick={handleClick}
          className="footer-logo flex justify-start items-center w-[fit]"
        >
          <img className="w-[60%]" src={FooterLogo} alt="" />
        </div>
        <div>
          <p className="text-2xl font-semibold text-white mb-4 max-[400px]:text-xl">
            Contact Us
          </p>
          <p className="text-white max-[400px]:text-xs">
            J-9, Anarkali Road, Krishna Nagar,
          </p>
          <p className="text-white max-[400px]:text-xs"> New Delhi-110051</p>
          <p className="text-white max-[400px]:text-xs">support@finovo.tech</p>
          <p className="text-white max-[400px]:text-xs">sales@finovo.tech</p>
          <p className="text-white max-[400px]:text-xs">+91-8448963816</p>
        </div>
      </div> */}
    </>
  );
}

export default ContactUs;
