import React from "react";
import { Request } from "../../component/requestDemo";
import { Provide } from "../../component/weProvide/index";
import { AI } from "../../component/aiComponent";
import { Footer } from "../../layout/footer";
import { OneStopSolution } from "../../component/oneStopSolution";
import { OperationComp } from "../../component/operationsComponent";
import ProductService from "../../component/productService";
import { Helmet } from "react-helmet";
import WeAreHere from "../../component/weAreHere";
import TrustedBrand from "../../component/trustedBrand";
const Home = () => {
  return (
    <>
    <div className="mt-16">
      <Helmet>
        <html lang="en" />
        <title>
          India’s one of the best tech solution providers, Finovo Tech
        </title>
        <meta
          name="description"
          content="Grow your business to the next level. We are committed to delivering result-oriented & cost-cutting automation solutions for all industries.
"
        />
      </Helmet>
      <h1 className="hidden">Grow your Business to Next Level</h1>
      <h2 className="hidden" >Add an impactful value to your company</h2>
      <Request />
      <AI />
      <ProductService />
      <Provide />
      <OperationComp />
      {/* <TrustedBrand/> */}
      <OneStopSolution />
      <WeAreHere/>
      {/* <Footer /> */}
      </div>
    </>
  );
};

export { Home };
