import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES_NAME } from "../../routers/routeConstants";
import Shadow from "../../assest/about/logoShadow.png";
import FooterLogo from "../../assest/about/footerLogo.png";
import { Footer } from "../../layout/footer";

function TermsAndCondition() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const crossRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleLinkClick = (linkTag) => {
    scrollToTop();
    handleClose();
    navigate(linkTag);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleRedirect = (pageUrl) => {
    window.open(pageUrl);
  };
  const handleOutsideClick = (e) => {
    if (
      menuRef.current &&
      crossRef.current &&
      !menuRef.current.contains(e.target) &&
      !crossRef.current.contains(e.target)
    ) {
      handleClose();
    }
  };
  const handleScroll = () => {
    const scrollThreshold = 700;
    if (window.scrollY > scrollThreshold) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);

      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleClick = () => {
    handleLinkClick(ROUTES_NAME.HOME);
  };
  return (
    <>
      <div className="sm:p-8 md:p-10 lg:p-12 xl:p-16 relative mt-16">
        <img
          className="absolute top-0 right-0 w-[300px] max-[596px]:w-[200px] max-[319px]:w-[130px]"
          src={Shadow} alt=""
        />
        <p className="sm:text-2xl lg:text-4xl font-bold mb-10">
          Terms And Condition
        </p>
        <p>When using the website http://www.finovotech.com, the user will be deemed to have accepted the accompanying terms and conditions. Please take the time to properly read these before using this site's services. </p>
        <p className="sm:text-lg lg:text-2xl text-start font-bold mb-4 mt-10">
          Definitions of Users
        </p>
        <p className="sm:text-sm lg:text-base text-start mb-10">
          "User" refers to any person or entity using the Site.
        </p>
        <p className="sm:text-lg lg:text-2xl text-start font-bold mt-4">
          Finovo Tech
        </p>
        <p className="sm:text-sm lg:text-base text-start">
          Finovo Technologies Pvt. Ltd. and/or its affiliated businesses are referred to as "Finovo Tech" in this context. <br />
          Site: www.finovotech.com, which is owned and run by Finovo Tech, is referred to as the "Site".

        </p>
        {/* <p className="sm:text-sm lg:text-base text-start">
          Customers create their passwords, use 2FA, keep them private, and
          don't share them with anybody else. Requiring the same PIN used for
          login, and sell transactions from demat accounts are prohibited.
          Clients using call and trade must enter a 4-digit PIN (personal
          identification number) and the last four digits of their Client ID.
        </p>
        <p className="sm:text-sm lg:text-base text-start">
          Through the 'Forgot Password' link, customers can reset their
          passwords at any moment and start the email reset process.
        </p>
        <p className="sm:text-sm lg:text-base text-start">
          It is the responsibility of clients to keep their login information
          secure and private. Any unauthorized use must be reported right away,
          with thorough details. Customers are aware of and accept the dangers
          involved with trading online, such as the possibility of hacking and
          illegal access. Any abuse or illegal activity done on their account
          puts them at risk.
        </p>
        <p className="sm:text-sm lg:text-base text-start">
          If a client discovers any illegal access, account inconsistencies,
          lost passwords, or system security vulnerabilities, they must report
          Finovo in writing right away.
        </p> */}
        <p className="sm:text-lg lg:text-2xl text-start font-bold mt-4">
          Acceptance of the Conditions
        </p>
        <p className="sm:text-sm lg:text-base text-start mb-3">
          You accept and agree to be bound by the following terms and conditions for your use of the site by using it. The Terms of Use are subject to change at any time without prior notification from Finovo Tech. The terms of use displayed for a particular area of the site shall take precedent over the terms of use in the event of a conflict with regard to your use of that area of the site.
        </p>
        <p className="sm:text-lg lg:text-2xl text-start font-bold mt-4">
          Ownership of Content
        </p>
        <p className="sm:text-sm lg:text-base text-start mb-3">
          Finovo Tech is the sole owner of all content found on this website. Finovo Tech is the owner of the software, text, photos, graphics, video, and audio used on this website. Without Finovo Tech's prior written consent, no content from this website may be reproduced, altered, uploaded, posted, republished, transmitted, or distributed in any way. All rights reserved that are not specifically granted herein.
        </p>
        <p className="sm:text-lg lg:text-2xl text-start font-bold mt-4">
          Restrictions on Liability
        </p>
        <p className="sm:text-sm lg:text-base text-start mb-3">
          Finovo Tech shall not be responsible for any damages, whether direct, indirect, incidental, or consequential, resulting from the use of the Site, from the inability to access the Site, or from any information on the Site.
        </p>
        <p className="sm:text-lg lg:text-2xl text-start font-bold mt-4">
          Compensation
        </p>
        <p className="sm:text-sm lg:text-base text-start mb-3">
          By using the content and services offered on the Site, the User agrees not to misuse it, and to pay, defend, and hold Finovo Tech harmless from and against any losses, expenses, damages, and costs, including reasonable attorneys' fees.
        </p>
        <p className="sm:text-lg lg:text-2xl text-start font-bold mt-4">
          Confidentiality
        </p>
        <p className="sm:text-sm lg:text-base text-start mb-3">
          Finovo Tech values the privacy of its users above all else. The User's information will not be disclosed to any outside parties unless mandated by law. Finovo Tech retains the authority to utilize the data in order to give the user a better customized online experience.
        </p>
        <p className="sm:text-lg lg:text-2xl text-start font-bold mt-4">
          Connections to Outside
        </p>
        <p className="sm:text-sm lg:text-base text-start mb-3">
          WebsitesThe website offers access to content, goods, and services from third parties as well as links to other websites. The content and accessibility of external websites are not within the control of Finovo Tech.
        </p>
        <p className="sm:text-lg lg:text-2xl text-start font-bold mt-4">
          Statement of Copyright
        </p>
        <p className="sm:text-sm lg:text-base text-start mb-3">
          Reserved all rights by Finovo Tech. Finovo Tech and/or its group companies own the registered trademarks for www.finovotech.com, the Finovo Tech logos, and artwork. Finovo Tech owns copyright to all items on this website, including but not limited to its design, text, graphics, and files.
        </p>
        <p className="sm:text-lg lg:text-2xl text-start font-bold mt-4">
          Authorization to Utilize Data/Resources
        </p>
        <p className="sm:text-sm lg:text-base text-start mb-3">
          Parts of the materials provided by Finovo Tech on this website may be electronically copied and printed in hard copy with the express condition that the material/information is used solely for non-commercial, personal usage. Any additional use of the content on this website, including commercial use, replication, distribution, or republication without Finovo Tech's prior written consent, is strictly forbidden.
        </p>
        <p className="sm:text-lg lg:text-2xl text-start font-bold mt-4">
          Disclaimer
        </p>
        <p className="sm:text-sm lg:text-base text-start mb-3">
          There may be typographical or technical problems in the materials and graphics on this website. Finovo Tech has the right to modify or enhance this content at any time. For any purpose, Finovo Tech disclaims all liability over the veracity of the data in the documents and visuals on this website. The visuals and documentation are all supplied "as is".
        </p>
      </div>
      <hr />
      {/* <Footer/> */}
    </>
  );
}

export default TermsAndCondition;
