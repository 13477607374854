import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES_NAME } from "../../routers/routeConstants";
import FooterLogo from "../../assest/about/footerLogo.png";
import { Helmet } from "react-helmet";
import Shadow from "../../assest/about/logoShadow.png"

function WebsiteDesignServices() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const crossRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleLinkClick = (linkTag) => {
    scrollToTop();
    handleClose();
    navigate(linkTag);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleRedirect = (pageUrl) => {
    window.open(pageUrl);
  };
  const handleOutsideClick = (e) => {
    if (
      menuRef.current &&
      crossRef.current &&
      !menuRef.current.contains(e.target) &&
      !crossRef.current.contains(e.target)
    ) {
      handleClose();
    }
  };
  const handleScroll = () => {
    const scrollThreshold = 700;
    if (window.scrollY > scrollThreshold) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);

      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleClick = () => {
    handleLinkClick(ROUTES_NAME.HOME);
  };
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>
          FINOVO’S ‘WEBSITE DESIGNING’ | A INNOVATION BEYOND IMAGINATION
        </title>
        <meta
          name="description"
          content="Transform your digital presence with Finovo's cutting-edge website design solutions.
"
        />
      </Helmet>
      <h1 className="hidden">Website Design Services</h1>
      <h2 className="hidden">finovo’s ‘Website Designing’- a innovation beyond imagination</h2>
      <div className="sm:p-8 md:p-10 lg:p-12 xl:p-16 relative mt-16">
        <img className="absolute top-0 right-0 w-[300px] max-[596px]:w-[200px] max-[319px]:w-[130px]" src={Shadow}/>
        <p className="sm:text-2xl lg:text-4xl font-bold mb-10">
          Website Design Services
        </p>
        <p className="sm:text-lg lg:text-2xl text-center font-bold mb-2">
          finovo’s ‘Website Designing’- a innovation beyond imagination
        </p>
        <p className="sm:text-sm lg:text-base text-center mb-10">
          To create a website that is effective for your success, get in touch
          with us. Your idea, our skill!
        </p>
        <p className="sm:text-lg lg:text-2xl text-start font-bold mb-2">
          Why should you choose finovo's 'Website Designing' tool first?
        </p>
        <p className="sm:text-lg lg:text-xl text-start mb-10">
          <li>
            Customized Design: Special websites that are in line with your goals
            and brand.
          </li>
          <li>
            Responsive Layouts: Ensuring smooth operation across all devices.
            Unlimited, free usage forever
          </li>
          <li>User-Centric Design: Simple navigation to boost engagement.</li>
          <li>
            E-commerce solutions: safe shopping carts for online transactions.
          </li>
          <li>Simple CMS: You can easily manage your content.</li>
          <li>
            Ongoing Support: We maintain the security and adequacy of your
            website.
          </li>
        </p>
      </div>
      <hr/>
      {/* footer */}
      {/* <div className="footer-color flex justify-between p-16 flex-wrap-reverse">
        <div
          onClick={handleClick}
          className="footer-logo flex justify-start items-center w-[fit]"
        >
          <img className="w-[60%]" src={FooterLogo} alt="" />
        </div>
        <div>
          <p className="text-2xl font-semibold text-white mb-4 max-[400px]:text-xl">
            Contact Us
          </p>
          <p className="text-white max-[400px]:text-xs">
            J-9, Anarkali Road, Krishna Nagar,
          </p>
          <p className="text-white max-[400px]:text-xs"> New Delhi-110051</p>
          <p className="text-white max-[400px]:text-xs">support@finovo.tech</p>
          <p className="text-white max-[400px]:text-xs">sales@finovo.tech</p>
          <p className="text-white max-[400px]:text-xs">+91-8448963816</p>
        </div>
      </div> */}
    </>
  );
}

export default WebsiteDesignServices;
