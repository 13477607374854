import React from "react";
import Vector from "../../assest/request/Vector.png";
import Arrow from "../../assest/request/forwardArrow.svg";
import "./styles.scss";
export const Request = () => {
  return (
    <>
      <div>
        <div className="request-body h-[90vh] flex flex-col justify-center items-center max-[820px]:h-[80vh] max-[512px]:h-[40vh]">
          <p className="text-2xl opacity-80 flex text-center font-bold max-[820px]:text-lg max-[512px]:text-sm">
            Add an impactful value to your company
          </p>
          <img className="vector w-[70%]" src={Vector} alt="" />
          <p className="hero-line text-7xl mt-4 flex text-center max-[1044px]:text-5xl">
            <span className="text-lightBlue">Grow your&nbsp;</span>
            <b>Business</b>
            <span className="text-tertiary">&nbsp;to Next Level </span>
          </p>

          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfNixhgm06_Eem2P04zh-NgTP5o5PpjetyvA5GaYaiFzi76GA/viewform?embedded=true">
            <button className="request-button bg-black relative text-white flex rounded-full mt-16 max-[512px]:mt-8 ">
              Request demo
              <img
                className="ms-2 mt-2"
                width="30px"
                src={Arrow}
                alt="forward arrow"
              />
            </button>
          </a>
        </div>
      </div>
    </>
  );
};
