import React from "react";
import "./styles.scss";
import { RButton } from "../../component/rButtton";

export const OneStopSolution = () => {
  return (
    <div className="sol-content sm:pt-12 sm:pb-12 md:pt-24 md:pb-24">
      <div className="flex justify-center flex-col text-center gap-6">
        <p className="font-bold text-white text-3xl">
          One stop solutions for 
          <span className="text-4xl text-black"> Every Industry</span>
        </p>
        <p className="mx-20 font-semibold max-[460px]:mx-8">
          We are committed to delivering result-oriented & cost-cutting<br/>
          automation solutions for all industries.
        </p>
        <div className="multi-button">
          <RButton
            externalClassName="cards-idl grow p-4 m-2 w-[170px]"
            buttonName="Capital Market"
            bgColor="bg-black"
            border="border-none"
            text="text-white"
          />
          <RButton
            externalClassName="cards-idl grow p-4 m-2 w-[170px]"
            buttonName="Finance"
            bgColor="bg-black"
            border="border-none"
            text="text-white"
          />
          <RButton
            externalClassName="cards-idl grow p-4 m-2 w-[170px]"
            buttonName="Manufacturing"
            bgColor="bg-black"
            border="border-none"
            text="text-white"
          />
          <RButton
            externalClassName="cards-idl grow p-4 m-2 w-[170px]"
            buttonName="Banking"
            bgColor="bg-black"
            border="border-none"
            text="text-white"
            />
         <br/>
          <RButton
            externalClassName="cards-idl grow p-4 m-2 w-[170px]"
            buttonName="Hospitals"
            bgColor="bg-black"
            border="border-none"
            text="text-white"
          />
          <RButton
            externalClassName="cards-idl grow p-4 m-2 w-[170px]"  
            buttonName="Real Estate"
            bgColor="bg-black"
            border="border-none"
            text="text-white"
          />
          <RButton
            externalClassName="cards-idl grow p-4 m-2 w-[170px]"
            buttonName="Hotels"
            bgColor="bg-black"
            border="border-none"
            text="text-white"
          />
            </div>
          
          <div className="font-semibold text-lg">
            <p>and many more...</p>
          </div>
      </div>
    </div>
  );
};
